import { AdwordsFAQs, AdwordsHeroSection, AdwordsHowItWorks, AdwordsReviews, AdwordsServices, AdwordsWhyUs, BrandingSection, FooterBottom, FreeTrialSection, HIPAACompliance, Pricing, RequestDiscoveryCall } from "src/Components";
import CallToAction from "src/Components/Adwords/CallToAction";
import Showcase from "src/Components/Adwords/Showcase";
import { getPricingPlans } from "src/Constant";
import dutyImg1 from 'src/Assets/Images/VmrHome/Duty1.svg';
import dutyImg2 from 'src/Assets/Images/VmrHome/Duty2.svg';
import dutyImg3 from 'src/Assets/Images/VmrHome/Duty3.svg';
import dutyImg4 from 'src/Assets/Images/VmrHome/Duty4.svg';
import feature1 from 'src/Assets/Images/VmsHome/Feature1.svg';
import feature2 from 'src/Assets/Images/VmsHome/Feature2.svg';
import feature3 from 'src/Assets/Images/VmsHome/Feature3.svg';
import feature4 from 'src/Assets/Images/VmsHome/Feature4.svg';
import feature5 from 'src/Assets/Images/VmsHome/Feature5.svg';
import feature6 from 'src/Assets/Images/VmsHome/Feature6.svg';
import storyImg2 from 'src/Assets/Images/Story/Story2.svg';
import storyImg3 from 'src/Assets/Images/Story/Story6.svg';
import storyImg4 from 'src/Assets/Images/Home/FaqHImg.svg';
import storyImg5 from 'src/Assets/Images/Home/WhyHImg.svg';
import policy2 from 'src/Assets/Images/Policy/P2.svg';
import policy3 from 'src/Assets/Images/Policy/P3.svg';
import policy5 from 'src/Assets/Images/Policy/P5.svg';
import policy7 from 'src/Assets/Images/Policy/P7.svg';
import policy8 from 'src/Assets/Images/Policy/P8.svg';
import service1 from 'src/Assets/Images/Services/Services1.svg';
import service2 from 'src/Assets/Images/Services/Services2.svg';
import service3 from 'src/Assets/Images/Services/Services3.svg';
import service4 from 'src/Assets/Images/Services/Services4.svg';
import service5 from 'src/Assets/Images/Services/Services5.svg';
import vision1 from 'src/Assets/Images/Vision/Vision1.svg';
import vision2 from 'src/Assets/Images/Vision/Vision2.svg';


export default function VMR_AdwordsPage() {

    const heroTitle = "Do You Need An Experience Virtual Medical Receptionist To Support Your Practice and Save 70% On Labor Cost?";
    const howItWorksTitle = "This entire process is streamlined and typically takes <b>1–2 weeks</b>, allowing you to quickly integrate a skilled Virtual Medical Receptionist into your team.";
    const requestTitle = "Get More Information About Our Virtual Medical Receptionist Staffing Services";
    const requestSubTitle = "We pride ourselves on having a warm and approachable team. You’ll love talking to us as we guide you through finding the perfect Virtual Medical Receptionist for your practice!";
    return ( 
        <div className="vdr-landing">
           <AdwordsHeroSection title={heroTitle}/>
           <BrandingSection />
           <AdwordsReviews reviews={reviews} title="Our Medical Practice Owners and Managers Love Us As Much As We Love Them"/>
           <Showcase titleVA="Who Are Our Virtual Medical Receptionists?" titleClient="What Our Clients Have To Say About Their Medical VA"/>
           {/* <CallToAction /> */}
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Medical Receptionist Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <AdwordsWhyUs title={"Why Hire Our Virtual Medical Receptionist"} whyusList={whyusList}/>
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Medical Receptionist Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <AdwordsServices title={"Ways Our Virtual Medical Receptionist Can Support Your Practice"} services={services}/>
           <AdwordsHowItWorks howItWorks={howItWorks} title={howItWorksTitle}/>
           <HIPAACompliance hippas={hippas}/>
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Medical Receptionist Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <Pricing plans={getPricingPlans('pricing-one')}/>
           <AdwordsFAQs faqs={faqs}/>
           <RequestDiscoveryCall title={requestTitle} subTitle={requestSubTitle}/>
           <FooterBottom />
        </div>
    )
}

const services = [
    {
        title: "Appointment Scheduling and Management",
        icon: dutyImg1,
        message: "",
        list: [
            "Book, reschedule, and confirm patient appointments.",
            "Send reminders to reduce no-shows and improve efficiency.",
        ]
    },
    {
        title: "Patient Communication",
        icon: dutyImg2,
        message: "",
        list: [
            "Handle patient inquiries via phone, email, or chat.",
            "Provide follow-up communication for post-appointment instructions or patient satisfaction checks.",
        ]
    },
    {
        title: "Insurance Verification and Authorization",
        icon: dutyImg3,
        message: "",
        list: [
            "Verify insurance coverage and benefits prior to appointments.",
            "Obtain prior authorizations for treatments or procedures.",
        ]
    },
    {
        title: "Billing and Collections",
        icon: dutyImg4,
        message: "",
        list: [
            "Generate and send invoices to patients.",
            "Follow up on overdue payments and assist patients with payment plans.",
        ]
    },
    {
        title: "Scribing Services",
        icon: vision1,
        message: "",
        list: [
            "Document patient encounters during telemedicine or in-person visits.",
            "Ensure accurate and timely charting within EHR systems.",
        ]
    },
    {
        title: "Patient Intake Process",
        icon: vision2,
        message: "",
        list: [
            "Collect and input patient information before appointments.",
            "Review and verify medical history and other required forms."
        ]
    },
    {
        title: "Prescription Refills and Management",
        icon: service1,
        message: "",
        list: [
            "Process prescription refill requests.",
            "Communicate with pharmacies to ensure timely delivery for patients."
        ]
    },
    {
        title: "Referrals and Care Coordination",
        icon: service1,
        message: "",
        list: [
            "Manage referrals to specialists or other healthcare providers.",
            "Coordinate with labs, imaging centers, or other facilities for follow-up care."
        ]
    },
    {
        title: "Remote Patient Monitoring",
        icon: service1,
        message: "",
        list: [
            "Assist with tracking patient data from remote monitoring devices (e.g., blood pressure monitors, glucose meters).",
            "Review and organize incoming data for provider analysis.",
            "Communicate with patients regarding alerts, follow-ups, or next steps based on monitored data"
        ]
    },
    {
        title: "Chart Preparation and Medical Records Management",
        icon: service1,
        message: "",
        list: [
            "Prepare and organize patient charts before appointments.",
            "Update and maintain accurate medical records in compliance with HIPAA regulations.",
            "Ensure all necessary documentation is available for the provider prior to consultations."
        ]
    },
    {
        title: "Administrative Task Management",
        icon: service1,
        message: "",
        list: [
            "Update and maintain accurate patient records.",
            "Organize practice schedules, including staff meetings or provider availability.",
        ]
    },
    {
        title: "Medical Coding and Billing Support",
        icon: service1,
        message: "",
        list: [
            "Assist with medical coding for procedures and diagnoses.",
            "Submit claims to insurance providers and follow up on denials.",
        ]
    },
    {
        title: "Medical Coding",
        icon: service1,
        message: "",
        list: [
            "Assign accurate codes for procedures, diagnoses, and treatments using ICD-10, CPT, and HCPCS standards.",
            "Ensure compliance with billing and insurance requirements to maximize reimbursements.",
            "Review provider notes to capture the correct codes for claims submission and reduce denials."
        ]
    },
    {
        title: "Patient Education",
        icon: service1,
        message: "",
        list: [
            "Provide patients with information about treatments, procedures, and medications.",
            "Share resources for managing chronic conditions or post-treatment care.",
        ]
    },
    {
        title: "Customer Experience Enhancement",
        icon: service1,
        message: "",
        list: [
            "Conduct patient satisfaction surveys.",
            "Address and resolve complaints or concerns promptly.",
        ]
    },
    {
        title: "Administrative Task Management",
        icon: service1,
        message: "",
        list: [
            "Update and maintain accurate patient records.",
            "Organize practice schedules, including staff meetings or provider availability.",
        ]
    },
    {
        title: "Marketing Support",
        icon: service1,
        message: "",
        list: [
            "Manage the practice’s Google Business Profile to enhance online presence.",
            "Respond to patient reviews and update business information.",
        ]
    },
    {
        title: "Social Media Management",
        icon: service1,
        message: "",
        list: [
            "Create and schedule posts to promote the practice’s services.",
            "Respond to inquiries and engage with the community online.",
        ]
    },
    {
        title: "Language Support",
        icon: service1,
        message: "",
        list: [
            "Provide bilingual communication for diverse patient populations, improving accessibility and satisfaction.",
        ]
    },
    {
        title: "Data Entry and Reporting",
        icon: service1,
        message: "",
        list: [
            "Compile reports on appointment trends, billing performance, and patient feedback.",
            "Track key metrics to help optimize practice operations."
        ]
    },
    {
        title: "After-Hours Support",
        icon: service1,
        message: "",
        list: [
            "Handle patient inquiries during evenings or weekends.",
            "Ensure timely responses to urgent administrative needs."
        ]
    },
    {
        title: "Compliance Support",
        icon: service1,
        message: "",
        list: [
            "Assist with HIPAA compliance documentation.",
            "Maintain updated patient consent forms and other required documentation."
        ]
    },
    {
        title: "Inventory and Supply Management",
        icon: service1,
        message: "",
        list: [
            "Monitor and track office supplies and medical inventory.",
            "Place orders to ensure the practice is fully stocked."
        ]
    },
    {
        title: "Customized Tasks",
        icon: service1,
        message: "",
        list: [
            "Adapt to the specific needs of your practice with tailored training and workflows.",
        ]
    },
]

const whyusList = [
    {
        title: "Significant Cost Savings",
        icon: service1,
        message: "Honest Taskers helps dental practices save 65% or more on labor costs by hiring Virtual Medical Receptionists (VMAs). Without the added expenses of taxes, benefits, and paid time off, practices can significantly reduce overhead while still receiving high-quality, reliable support.",
        list: []
    },
    {
        title: "Access to Skilled Talent",
        icon: service2,
        message: "Honest Taskers provides highly trained VAs who excel in:",
        list: [
            "Insurance verification and claims processing",
            "Appointment scheduling and follow-ups",
            "Patient communication",
            "Google business profiles optimization and social media management",
            "EMR software proficiency, Tebra, DrChrono, athenahealth, eClinicalWorks, Practice Fusion, AdvanceMD and more"
        ]
    },
    {
        title: "Bilingual Support for Diverse Patient Bases",
        icon: service3,
        message: "For practices serving multilingual communities, Honest Taskers offers bilingual VAs, enhancing communication and patient satisfaction, especially in Spanish-speaking markets.",
        list: []
    },
    {
        title: "Reduced Turnover and Reliable Support",
        icon: service4,
        message: "High turnover is a common issue for in-office staff. Honest Taskers’ VAs are reliable, consistent, and have a proven track record of long-term commitment, eliminating the need for frequent retraining.",
        list: []
    },
    {
        title: "Enhanced Practice Efficiency",
        icon: service5,
        message: "Delegating administrative tasks to a VDA allows in-office staff to focus on clinical duties, streamlining operations and improving patient care.",
        list: []
    },
    {
        title: "Proactive and Versatile Services",
        icon: vision1,
        message: "Honest Taskers’ VAs go beyond traditional tasks. For example, during slow periods, they can optimize Google Business Profiles, improving local search rankings and attracting new patients.",
        list: []
    },
    {
        title: "Flexibility and Scalability",
        icon: vision2,
        message: "Honest Taskers’ VAs work the hours you need, including evenings or weekends, and require no physical office space. As your practice grows, you can easily scale your virtual team.",
        list: []
    },
    {
        title: "HIPAA-Compliant and Secure",
        icon: storyImg5,
        message: "All Honest Taskers VAs are trained in HIPAA compliance, ensuring patient data security and confidentiality.",
        list: []
    },
    {
        title: "Improved Patient Retention and Growth",
        icon: storyImg4,
        message: "Fewer missed calls, timely follow-ups, and excellent service lead to higher patient retention rates and increased new patient inquiries.",
        list: []
    },
    {
        title: "Proven Track Record",
        icon: storyImg3,
        message: "Dental practices using Honest Taskers’ VAs report improved efficiency, reduced costs, and measurable growth, such as increased bookings and fewer no-shows.",
        list: []
    },
    {
        title: "Two-Week Trial to Ensure the Perfect Fit",
        icon: policy2,
        message: "Honest Taskers offers a two-week trial so you can assess whether the VDA meets your practice’s unique needs before committing.",
        list: []
    },
    {
        title: "No Long-Term Commitment",
        icon: policy5,
        message: "With no long-term contracts, Honest Taskers provides flexibility to adapt to your practice’s evolving needs.",
        list: []
    },
    {
        title: "Core Values-Driven Hiring",
        icon: policy8,
        message: "Honest Taskers doesn’t just hire for talent; they prioritize core values that align with their dental partners, ensuring a positive impact on your team culture and patient interactions.",
        list: []
    },
    {
        title: "Customized Training for Unique Practice Needs",
        icon: policy7,
        message: "If your practice has specific requirements, Honest Taskers offers customized training to ensure their VAs are fully prepared to meet your unique needs. This tailored approach helps optimize performance and achieve the desired outcomes for your practice.",
        list: []
    },
]

const faqs = [
    {
        question: "What tasks can the Virtual Medical Receptionist handle for my practice?",
        answer: "Our Virtual Medical Receptionists (VMAs) can manage a wide range of tasks, including:",
        options: [
            "Appointment scheduling and confirmation",
            "Patient intake and record updates",
            "Insurance verification and claims processing",
            "Patient communication, such as reminders and inquiries",
            "Billing, coding, and payment follow-ups",
            "Prescription refills and referrals",
            "Scribing and chart preparation",
            "Managing Google Business Profiles and social media",
            "Compliance documentation and reporting",
            "Care coordination and remote patient monitoring"
        ]
    },
    {
        question: "How experienced is the Virtual Medical Receptionist in healthcare-specific tasks?",
        answer: "Our VMAs are highly trained and experienced in healthcare-specific administrative and clinical tasks. They are familiar with medical terminology, processes, and popular EHR systems like Athenahealth, Kareo, and eClinicalWorks."
    },
    {
        question: "Are they HIPAA-compliant, and how is patient data security ensured?",
        answer: "Yes, all Honest Taskers VMAs are HIPAA-trained and compliant. We use secure, encrypted communication channels and access controls to safeguard patient data. Regular training and audits ensure continued compliance."
    },
    {
        question: "What is the educational background of your Virtual Medical Receptionists?",
        answer: "Our Virtual Medical Receptionists (VMAs) have a strong educational background, including bachelor’s degrees in nursing, healthcare, or related fields, and some even hold degrees in medicine. Many are registered nurses (RNs) or have formal training in medical-related disciplines, making them adept at handling both clinical and administrative tasks. Additionally, some have specialized certifications in medical coding, billing, or transcription, further enhancing their skill set. Combined with extensive training in medical terminology, HIPAA compliance, and practice management software, our VMAs are highly qualified to support the diverse needs of medical practices."
    },
    {
        question: "What hours of availability can they provide?",
        answer: "Our VMAs work flexible hours to accommodate your practice’s needs, including evenings, weekends, and during peak times. We ensure their schedules align with your time zone and operational requirements."
    },
    {
        question: "Can the Virtual Dental Receptionist assist with patient communication?",
        answer: "Absolutely. Our VMAs excel in patient communication, including:",
        options: [
            "Answering phone calls",
            "Sending appointment reminders",
            "Managing patient inquiries through email or chat",
            "Handling follow-ups to improve satisfaction and retention"
        ]
    },
    {
        question: "What kind of training and onboarding is required for the Medical Receptionist ?",
        answer: "Honest Taskers provides initial training tailored to general dental tasks. If your practice has specific protocols, we offer customized training to ensure seamless integration. Onboarding typically takes 1-2 weeks."
    },
    {
        question: "What software platforms are they proficient in?",
        answer: "Our VMAs are trained in popular medical software, including:",
        options: [
            "Athenahealth",
            "Kareo",
            "eClinicalWorks",
            "Epic",
            "AdvancedMD",
            "ETC",
            "If your practice uses other tools, we can provide additional training."
        ]
    },
    {
        question: "How is their performance monitored and evaluated?",
        answer: "We track VMA performance through productivity reports, regular check-ins, and feedback from your practice. Our monitoring includes adherence to assigned tasks, timely responses, and compliance with protocols. Any concerns are addressed promptly."
    },
    {
        question: "What communication tools do they use to stay in touch with the team?",
        answer: "Our VMAs use secure and efficient platforms like Slack, Microsoft Teams, Zoom, and HIPAA-compliant email systems to ensure seamless collaboration with your team."
    },
    {
        question: "What is the cost structure, and are there any long-term contracts?",
        answer: "Our pricing is flexible and based on your needs, with options for hourly or flat-rate packages. There are no long-term contracts, giving you the freedom to scale or adjust as necessary."
    },
    {
        question: "Can they handle insurance verification and claims processing?",
        answer: "Yes, our VMAs specialize in insurance-related tasks, including verifying patient benefits, submitting claims, and following up on denials or pending claims."
    },
    {
        question: "How do they handle patient complaints or difficult conversations?",
        answer: "Our VMAs are trained in professional communication and problem-solving. They handle complaints empathetically and escalate issues to the appropriate team member when necessary."
    },
    {
        question: "What languages do they speak fluently?",
        answer: "Many of our VMAs are bilingual, with fluency in English and other languages such as Spanish. This ensures better communication with diverse patient bases."
    },
    {
        question: "Can they assist with social media management or marketing tasks?",
        answer: "Yes, our VMAs can support marketing by:",
        options: [
            "Creating and scheduling posts for social media",
            "Managing social media accounts",
            "Responding to online inquiries",
            "Optimizing Google Business Profiles for better local SEO"
        ]
    },
    {
        question: "How do they ensure accuracy in documenting patient records?",
        answer: "Our VMAs follow strict protocols for accuracy and compliance when entering patient data. They double-check entries and adhere to HIPAA guidelines to maintain record integrity."
    },
    {
        question: "What tools or technology do they need from the practice?",
        answer: "Typically, our VMAs require:",
        options: [
            "Access to your EHR system",
            "Secure communication tools",
            "A web phone or VOIP system",
            "Remote access to relevant files or systems"
        ]
    },
    {
        question: "Do they have experience working in a fast-paced medical practice?",
        answer: "Yes, many of our VMAs have worked in busy practices and are skilled at handling high patient volumes, managing schedules efficiently, and staying organized under pressure."
    },
    {
        question: "How quickly can they adapt to our office workflows and protocols?",
        answer: "Our VMAs are quick learners and typically adapt to your workflows within 2 -12 weeks. Customized training during onboarding ensures they are ready to hit the ground running."
    },
    {
        question: "What happens if they are unavailable or have connectivity issues?",
        answer: "Honest Taskers ensures continuity with backup staff or quick replacements in case of emergencies. We also require our VAs to work from reliable, secure environments with strong internet connections and have back up incase of disruption"
    },
    {
        question: "Can they assist with maintaining compliance documentation?",
        answer: "Yes, our VMAs can help manage and update compliance-related documents, such as OSHA records, patient forms, and audit logs, ensuring your practice stays compliant."
    },
    {
        question: "Where are your Virtual Dental Receptionists located?",
        answer: "Our Virtual Dental Receptionists are based in the Philippines and Latin America."
    }
]

const reviews = [
    {
        name: "Dr. Sarah Mitchell",
        initials: "SM",
        subTitle: "Practice Owner",
        message: "Our Virtual Medical Receptionist from Honest Taskers has been a game-changer for our practice. She seamlessly handles a variety of administrative tasks, including patient scheduling, insurance verification, patient intaket, and follow-up communications. Her efficiency and attention to detail have significantly reduced our team's workload, allowing us to focus more on patient care. What truly stands out is her professionalism and ability to adapt to our workflow. She has become an integral part of our team, and we can’t imagine running the practice without her support. Honest Taskers has exceeded our expectations!"
    },
    {
        name: " Lynn Roberts",
        initials: "LR",
        subTitle: "Practice Manager, Together Women’s Health",
        message: "As a practice manager, I’ve worked with Virtual Medical Receptionists from other companies before, but the quality of Honest Taskers' Virtual Medical Receptionists is truly unmatched. We now have four Virtual Medical Receptionists from Honest Taskers, and their support has reduced our need for in-person Medical Receptionists by 40%. Our Virtual Medical Receptionists conduct live patient intakes via Zoom with professionalism and warmth, and our patients absolutely love them. They’ve become such an integral part of our team that we even have their pictures on the wall with the rest of our staff! Honest Taskers has completely transformed our operations, and I couldn’t recommend them enough."
    },
    {
        name: "Dr. Mark Stevens",
        subTitle: "Psychiatrist",
        initials: "MS",
        message: "As a psychiatrist running a telemedicine-only practice, the Virtual Medical Receptionist from Honest Taskers has been absolutely essential. Since I don’t have any in-person staff, my Virtual Medical Receptionist handles all the administrative tasks—patient scheduling, insurance verification, billing, follow-ups, and more. Her efficiency and reliability allow me to focus 100% on patient care without worrying about the day-to-day operations of the practice. She truly runs the administrative side of the practice seamlessly, and I couldn’t do this without her. Honest Taskers has been a game-changer for my telemedicine practice!"
    }
]

const howItWorks = [
    {
        title: "Submit Your Inquiry & Discovery Call",
        img: dutyImg1,
        message: "Share your needs by submitting an inquiry, and we’ll schedule a discovery call to discuss your requirements, clarify expectations, and answer any questions you have."
    },
    {
        title: "Interview Hand-Selected Candidates",
        img: dutyImg2,
        message: "We’ll provide a shortlist of qualified candidates based on your needs. You’ll interview them to ensure they’re the perfect match for your practice."
    },
    {
        title: "Start Working with Your VMR",
        img: dutyImg3,
        message: "Once you’ve chosen your candidate, they’ll start supporting your practice right away, providing tailored assistance and immediate value."
    }
]

const hippas = [
    {
        img: feature1,
        title: "Comprehensive Training for Virtual Medical Receptionists",
        message: "All Virtual Medical Receptionists (VMRs) receive extensive training on HIPAA regulations, including:",
        options: [
            "Proper handling of Protected Health Information (PHI).",
            "Identifying and mitigating risks of data breaches.",
            "Secure communication practices to prevent unauthorized access."
        ]
    },
    {
        img: feature2,
        title: "Secure Communication Channels",
        message: "We ensure that all communication between VMRs and your practice is conducted via HIPAA-compliant platforms, such as:",
        options: [
            "Encrypted email services.",
            "Secure file-sharing systems.",
            "HIPAA-compliant phone and video conferencing tools."
        ]
    },
    {
        img: feature3,
        title: "Access Control and Authorization",
        options: [
            "VMRs are granted access only to the information necessary to perform their duties.",
            "Role-based permissions ensure that sensitive data is restricted to authorized personnel."
        ]
    },
    {
        img: feature1,
        title: "Data Encryption",
        message: "All transmitted and stored data is encrypted to safeguard it from potential breaches or unauthorized access."
    },
    {
        img: feature4,
        title: "Regular Audits and Monitoring",
        message: "Honest Taskers conducts routine internal audits to ensure ongoing compliance with HIPAA standards and proactively address potential vulnerabilities."
    },
    {
        img: feature5,
        title: "Business Associate Agreements (BAA)",
        message: "We provide Business Associate Agreements to all dental practices, ensuring a clear understanding of responsibilities and compliance requirements."
    },
    {
        img: feature6,
        title: "Secure Work Environment for VMRs",
        options: [
            "VMRs work in private, monitored environments to prevent unauthorized viewing or sharing of PHI.",
            "Policies are in place to prevent the use of unsecured devices or networks."
        ]
    },
    {
        img: feature1,
        title: "Incident Management Protocols",
        message: "In the rare event of a security incident, Honest Taskers has a well-defined protocol to:",
        options: [
            "Immediately contain and resolve the issue.",
            "Notify your practice promptly.",
            "Take corrective actions to prevent recurrence."
        ]
    },
    {
        img: feature1,
        title: "Continuous Education",
        message: "VMRs participate in ongoing HIPAA training to stay updated on evolving regulations and best practices for maintaining data security."
    },
]
import React from "react";
import './style.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";
import { ArrowLeftOne, ArrowRightOne } from "src/Constant";

export default function Jobs() {

    const JobItem = ({}) => {
        return (
            <div className="job-item a-c">
                <div className="col-lg-4 user-area v-c">
                    <div className="avatar v-r h-c v-c user-0">F O</div>
                    <div className="user-info v-r">
                        <h2>Felicia O</h2>
                    </div>
                </div>
                <div className="col-lg-2 arrow-col">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFcAAABGCAMAAACOnPhgAAAAe1BMVEUAAAAhou4noesloesmouwmoeoon+8louwloOokoesgn+8loes0qe0louskouslouwmouoln+8gn98mousno+0loOv///9Bre7J6PqS0fXk9P3y+v5Ps+93xfPk8/zW7vuu3fiFy/QzqOyg1vdcufDj8/0mouu74vmh1/be1xPGAAAAFnRSTlMAH79/398gnzBfEM/vjz/vbzAQr29P8JAuqwAAAWxJREFUWMPV2OtOwkAQhuHaIy0tVIFZtke2HPT+r9A1kKhMorH5vgTfC3j+TDedTPDXijzaLgJ49fpknclSMBuZRnw2TvGsgjEsHs5NK0KA40aEAC86+dbRwwxXDhi4mFoFQ15I5uQeXiPgIrH38GuJgJ80PPLgHQmecgS8VHBjEHDBg52GIwjck+ANCQ4yHtyS4I4EVxruIHCk4JYHVxDYDArOOLD0X+BVFc7s8hOcmeN+do0oeFlcP5i+FVifcGpaAWc/4K0TeG7jZ2oF37TiuK7muDbiuKcdZW5j4h/bCc/6sQVBeESz5eK2wgwE1sPZxczuoFbXK3uj57VKLGMjTmPNJk//iLWPzL5x2AODfSaxaw5b7hW7fAGwI4PNJ8X2JDYEsKZRLGDXqxXbIthIs93jsrlpKJt/PGqWcTcajGcRrmZB9yjFQto4EKuPMIrFwp6tAZ6Ch+vlBQn3+/PZlp4Fl4dxXP3+z3kHpAq2zyS+TfkAAAAASUVORK5CYII=" alt="arrow" />
                </div>
                <div class="col-lg-6 content-list v-r">
                    <div class="content-item"><b>subject: </b>&nbsp;&nbsp;Piano</div>
                    <div class="content-item"><b>Grade Level: </b>&nbsp;&nbsp;Music Lessons</div>
                    <div class="content-item"><b>Time: </b>&nbsp;&nbsp;. Student wants to start tutoring: 10/02/2024/Evening/6 pm - 12 am, </div>
                </div>
            </div>
        )
    }

//     <div class="owl-nav">
//         <button type="button" role="presentation" class="owl-prev disabled">
//     <div style="width:50px;height:50px;display:flex;align-items:center;justify-content:center;background:white;border:2px solid #23A4EF">
//         <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARBAMAAAAvapGNAAAALVBMVEUAAAAipe8ipO8gn+8ipO8ipO8on+8jpO8jpO8ipO8jpPEjpO8ipe8ko+8jpO973OREAAAADnRSTlMAf58Q388g76CPX79vQKFUFDkAAABXSURBVAjXYwACDi8GGOh7DmNxvHsJZTHvezcByjR99xAmGPdOAC74RBAMZBn83kHBcwTzHUPKu+dKYKDFwOYH1wYUfghjsoFNQ9iBsIQBLvyGAQ4MwCQAHigp3rddvX4AAAAASUVORK5CYII=" alt="nav-arrow" />
//     </div>
// </button><button type="button" role="presentation" class="owl-next">
//     <div style="width:50px;height:50px;display:flex;align-items:center;justify-content:center;background:white;border:2px solid #23A4EF">
//         <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARBAMAAAAvapGNAAAALVBMVEUAAAAipO8ipe8gn+8ipO8io+8ko+8gn+8jpO8jpO8iou4ipu8kou0ko+8jpO8onPV9AAAADnRSTlMAn38Qz9+AIO+/YI9wQEJ4ICkAAABUSURBVAjXYwCDFRsYYODdQzhT7p0AjMn47okBjK33zhnGZAIJX1ICgzig8DsYeIlgPmVYKAgGfe/CodrY3j0tgDLzEIIIK/IQNiDZe24y3GXMIAIAFcItRBQ5mKcAAAAASUVORK5CYII=" alt="nav-arrow">
//     </div>
// </button></div>

    return (
        <div className="jobs-component">
            <div className="container v-r">
                <h1>Jobs</h1>
                <div className="jobs-list show-web-flex v-r">
                    <div className="col-lg-12 jobs v-r">
                        <JobItem />
                        <JobItem />
                        <JobItem />
                    </div>
                </div>
                <div className="jobs-list-mobile">
                    <div className="col-lg-12 jobs v-r">
                        <Media
                            queries={{
                                lg: "(min-width: 1655px)",
                                md: "(min-width: 1360px) and (max-width: 1654px)",
                                sm: "(min-width: 1032px) and (max-width: 1359px)",
                                normal: "(max-width: 1031px)",
                            }}
                        >
                        {(matches) => (
                            <OwlCarousel 
                                className="owl-theme"
                                stagePadding={
                                    matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 0
                                }
                                margin={
                                    matches.lg ? 20 : matches.md ? 20 : matches.sm ? 10 : 0
                                }
                                items={matches.lg ? 4 : matches.md ? 3 : matches.sm ? 2 : 1}
                                loop
                                nav
                                navText={[ArrowLeftOne, ArrowRightOne]}
                                dots={false}
                                autoplay
                                rewind
                                autoplayHoverPause
                                autoplayTimeout={2000}
                                >
                                    {
                                        Array(4).fill(true).map((item, index) => (
                                            <JobItem key={index}/>
                                        ))
                                    }
                                </OwlCarousel>
                            )}
                        </Media>
                    </div>
                </div>
            </div>
        </div>
    )
}
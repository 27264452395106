import { SUBMIT_CONTACT, SUBMIT_CONTACT_SUCCESS, SUBMIT_RECEPTION_CONTACT, SUBMIT_REQUEST_CALL, SUBMIT_REQUEST_CALL_SUCCESS, SUBMIT_REQUEST_CALL_FAIL, SUBMT_CONTACT_FAIL } from '../Type'

const INITIAL = {
    submitContact_loading: false,
    submitContact_success: false,
    submitRequest_loading: false,
    submitRequest_success: false
}

export default (state = INITIAL, action) => {
    switch(action.type) {
        case SUBMIT_CONTACT: {
            return {
                ...state,
                submitContact_loading: true,
                submitContact_success: false
            }
        }
        case SUBMIT_RECEPTION_CONTACT: {
            return {
                ...state,
                submitContact_loading: true,
                submitContact_success: false
            }
        }
        case SUBMIT_CONTACT_SUCCESS: {
            return {
                ...state,
                submitContact_loading: false,
                submitContact_success: true
            }
        }
        case SUBMT_CONTACT_FAIL: {
            return {
                ...state,
                submitContact_loading: false,
                submitContact_success: false
            }
        }
        case SUBMIT_REQUEST_CALL: {
            return {
                ...state,
                submitRequest_loading: true,
                submitRequest_success: false
            }
        }
        case SUBMIT_REQUEST_CALL_SUCCESS: {
            return {
                ...state,
                submitRequest_loading: false,
                submitRequest_success: true
            }
        }
        case SUBMIT_REQUEST_CALL_FAIL: {
            return {
                ...state,
                submitRequest_loading: false,
                submitRequest_success: false
            }
        }
        default: 
            return state
    }
}
import React from "react";

export default function SubmitArea({onContinue, onBack}) {

    return (
        <div className="submit-area">
            <div className="submit-container container">
                <div className="btn-area">
                    <div className="back-btn" onClick={onBack}>
                        <div className="back-icon center-item">
                            <div className="fa fa-arrow-left" style={{color:"rgb(192, 197, 201)"}}></div>
                        </div>
                        <div className="back-text center-item">Back</div>
                    </div>
                    <div>
                        <div className="startbutton-component start-btn show-web-flex" onClick={onContinue}>
                            <div className="disable-btn-area" style={{height:"65px"}}>
                                <div className="btn-text btn-element">Continue<div className="number-color">1</div>
                                    <div className="ms-2 fa fa-arrow-right" style={{fill:"white"}}></div>
                                </div>
                            </div>
                            <div className="bg-dot" style={{height:"65px"}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { GET_COURSES, TOGGLE_PORTAL_SIDE_MENU } from "../Type"


export const togglePortalSideMenu = (isOpen) => {
    return {
        type: TOGGLE_PORTAL_SIDE_MENU,
        data: { isOpen }
    }
}

export const getCoursesAction = () => {
    return {
        type: GET_COURSES,
        data: {}
    }
}
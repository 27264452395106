import { 
    TOGGLE_ATTENTION_TEST_MODAL,
    TOGGLE_CERTIFICIATION_MODAL,
    TOGGLE_EXPERIENCE_MODAL,
    TOGGLE_SKILL_FORM_MODAL,
 } from "../Type"


const INITIAL = {
    showExperienceModal: false,
    showCertificationModal: false,
    showSkillModal: false,
    profileStage: 11,
    showAttentionTest: false
}

export default (state = INITIAL, action) => {
    switch(action.type) {
        case TOGGLE_EXPERIENCE_MODAL: {
            const { show } = action.data
            return {
                ...state,
                showExperienceModal: show
            }
        }
        case TOGGLE_CERTIFICIATION_MODAL: {
            const { show } = action.data
            return {
                ...state,
                showCertificationModal: show
            }
        }
        case TOGGLE_SKILL_FORM_MODAL: {
            const { show } = action.data
            return {
                ...state,
                showSkillModal: show
            }
        }
        case TOGGLE_ATTENTION_TEST_MODAL: {
            const { show } = action.data
            return {
                ...state,
                showAttentionTest: show
            }
        }
        default: 
            return state
    }
}
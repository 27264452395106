import { GET_COURSES, GET_COURSES_FAIL, GET_COURSES_SUCCESS, TOGGLE_PORTAL_SIDE_MENU } from "../Type"

const INITIAL = {
    portalSideMenuOpen: false,
    courses: null,
    loadingCourses: false
}

export default (state = INITIAL, action) => {
    switch(action.type) {
        case TOGGLE_PORTAL_SIDE_MENU: {
            const { isOpen } = action.data
            return {
                ...state,
                portalSideMenuOpen: isOpen
            }
        }
        case GET_COURSES: {
            return {
                ...state,
                loadingCourses: true
            }
        }
        case GET_COURSES_SUCCESS: {
            return {
                ...state,
                loadingCourses: false,
                courses: action.data
            }
        }
        case GET_COURSES_FAIL: {
            return {
                ...state,
                loadingCourses: false
            }
        }
        default:
            return state
    }
}
import React, { useState } from "react";
import './style.scss';
import SubmitArea from "../SubmitArea";

export default function HeadShot() {
    const [image, setImage] = useState(null);

  // Handle image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      console.log(imageUrl)
      setImage(imageUrl); // Set the preview URL
    }
  };

    return (
        <div className="head-shot">
            <h1>Professional Headshot</h1>
            <div className="examples mt-5">
                <div className="examples">
                    <div className="img-wrapper">
                        <img src="https://img.freepik.com/free-photo/portrait-expressive-young-man-wearing-formal-suit_273609-6942.jpg?t=st=1733172969~exp=1733176569~hmac=1cb10940b02b394c665c5feb6354e1e9d84a4ca2042b67e53d0e28b3741d1bc9&w=1480" alt="good example"/>
                    </div>
                    <div className="img-wrapper">
                        <img src="https://img.freepik.com/free-photo/portrait-businesswoman-isolated-home_23-2148813223.jpg?t=st=1733173042~exp=1733176642~hmac=bc1eea91e947284a53dbe20aa9a723a7e3ce100f1842255e60a6d3e4c4e134e2&w=996" alt="good example 2"/>
                    </div>
                    <div className="img-wrapper">
                        <img src="https://img.freepik.com/free-photo/business-man-banner-concept-with-copy-space_23-2149601461.jpg?t=st=1733173079~exp=1733176679~hmac=94a9535887c58bfa69055c9c1619da7206afcf91e1d8540f110986cdebcc3cc5&w=1800" alt="good example 3"/>
                    </div>
                    <div className="img-wrapper bad">
                        <img src="https://img.freepik.com/free-photo/confident-middle-aged-man-wearing-white-t-shirt-with-tie-isolated-orange-wall_141793-83764.jpg?t=st=1733173169~exp=1733176769~hmac=a134e6a6a44f2c8675d00e900ae7088c0de8cb7f13e05c01eb18a71d048a80b6&w=1060" alt="bad example"/>
                    </div>
                    <div className="img-wrapper bad">
                        <img src="https://img.freepik.com/free-photo/short-haired-woman-looking-into-camera-isolated-background-active-emotional-lady-black-jacket-makes-funny-face-white-backdrop_197531-18512.jpg?t=st=1733173227~exp=1733176827~hmac=334d04da30c7120824b81da1ff42d5b96cbc4a27b20347e41a4afc6be786e4f5&w=1480" alt="bad example 2"/>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                {
                    image && (
                        <div className="preview">
                            <img src={image} alt="preview" />
                        </div>
                    )
                }
                <div className="upload-btn">
                    <input type="file"  accept="image/*" onChange={handleImageChange}/>
                    <div className="overlay">Upload Photo</div>
                </div>
            </div>
            <SubmitArea onContinue={() => {}} onBack={() => {}}/>
        </div>
    )
}
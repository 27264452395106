import { AdwordsFAQs, AdwordsHeroSection, AdwordsHowItWorks, AdwordsReviews, AdwordsServices, AdwordsWhyUs, BrandingSection, FooterBottom, FreeTrialSection, HIPAACompliance, Pricing, RequestDiscoveryCall } from "src/Components";
import CallToAction from "src/Components/Adwords/CallToAction";
import Showcase from "src/Components/Adwords/Showcase";
import { getPricingPlans } from "src/Constant";
import dutyImg1 from 'src/Assets/Images/VmrHome/Duty1.svg';
import dutyImg2 from 'src/Assets/Images/VmrHome/Duty2.svg';
import dutyImg3 from 'src/Assets/Images/VmrHome/Duty3.svg';
import dutyImg4 from 'src/Assets/Images/VmrHome/Duty4.svg';
import feature1 from 'src/Assets/Images/VmsHome/Feature1.svg';
import feature2 from 'src/Assets/Images/VmsHome/Feature2.svg';
import feature3 from 'src/Assets/Images/VmsHome/Feature3.svg';
import feature4 from 'src/Assets/Images/VmsHome/Feature4.svg';
import feature5 from 'src/Assets/Images/VmsHome/Feature5.svg';
import feature6 from 'src/Assets/Images/VmsHome/Feature6.svg';
import storyImg2 from 'src/Assets/Images/Story/Story2.svg';
import storyImg3 from 'src/Assets/Images/Story/Story6.svg';
import storyImg4 from 'src/Assets/Images/Home/FaqHImg.svg';
import storyImg5 from 'src/Assets/Images/Home/WhyHImg.svg';
import policy2 from 'src/Assets/Images/Policy/P2.svg';
import policy3 from 'src/Assets/Images/Policy/P3.svg';
import policy5 from 'src/Assets/Images/Policy/P5.svg';
import policy7 from 'src/Assets/Images/Policy/P7.svg';
import policy8 from 'src/Assets/Images/Policy/P8.svg';
import service1 from 'src/Assets/Images/Services/Services1.svg';
import service2 from 'src/Assets/Images/Services/Services2.svg';
import service3 from 'src/Assets/Images/Services/Services3.svg';
import service4 from 'src/Assets/Images/Services/Services4.svg';
import service5 from 'src/Assets/Images/Services/Services5.svg';
import vision1 from 'src/Assets/Images/Vision/Vision1.svg';
import vision2 from 'src/Assets/Images/Vision/Vision2.svg';


export default function VDR_AdwordsPage() {

    const heroTitle = "Do You Need an Experienced Virtual Dental Receptionist to Help Your Practice Run Efficiently and Save Over 65% on Labor Costs?";
    const howItWorksTitle = "This entire process is streamlined and typically takes <b>1–2 weeks</b>, allowing you to quickly integrate a skilled Virtual Dental Receptionist into your team.";
    const requestTitle = "Get More Information About Our Virtual Dental Receptionist Staffing Services";
    const requestSubTitle = "We pride ourselves on having a warm and approachable team. You’ll love talking to us as we guide you through finding the perfect Virtual Dental Receptionist for your practice!";
    return ( 
        <div className="vdr-landing">
           <AdwordsHeroSection title={heroTitle}/>
           <BrandingSection />
           <AdwordsReviews reviews={reviews}/>
           <Showcase />
           {/* <CallToAction /> */}
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Dental Receptionist Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <AdwordsWhyUs title={"Why Hire Our Virtual Dental Receptionist"} whyusList={whyusList}/>
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Dental Receptionist Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <AdwordsServices title={"Ways Our Virtual Dental Receptionist Can Support Your Practice"} services={services}/>
           <AdwordsHowItWorks howItWorks={howItWorks} title={howItWorksTitle}/>
           <HIPAACompliance hippas={hippas}/>
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Dental Receptionist Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <Pricing plans={getPricingPlans('pricing-one')}/>
           <AdwordsFAQs faqs={faqs}/>
           <RequestDiscoveryCall title={requestTitle} subTitle={requestSubTitle}/>
           <FooterBottom />
        </div>
    )
}

const services = [
    {
        title: "Appointment Scheduling and Management",
        icon: dutyImg1,
        message: "",
        list: [
            "Book, reschedule, and confirm patient appointments.",
            "Send reminders to reduce no-shows.",
            "Handle cancellations efficiently to optimize the schedule."
        ]
    },
    {
        title: "Patient Communication",
        icon: dutyImg2,
        message: "",
        list: [
            "Respond to patient inquiries via phone, email, or chat.",
            "Provide details about treatments, office policies, or payment plans.",
            "Handle follow-ups for patient satisfaction and treatment compliance."
        ]
    },
    {
        title: "Insurance Verification and Claims Processing",
        icon: dutyImg3,
        message: "",
        list: [
            "Verify patient insurance benefits and eligibility.",
            "Submit claims to insurance providers and follow up on denials or pending claims.",
            "Ensure accuracy in billing and coding for faster reimbursements."
        ]
    },
    {
        title: "Billing and Payment Processing",
        icon: dutyImg4,
        message: "",
        list: [
            "Generate and send invoices.",
            "Follow up on overdue payments and assist patients with payment plans.",
            "Manage accounts receivable to streamline cash flow."
        ]
    },
    {
        title: "Managing Patient Records",
        icon: vision1,
        message: "",
        list: [
            "Update and maintain accurate patient files.",
            "Ensure compliance with HIPAA regulations for data security.",
            "Assist in transitioning from paper-based records to digital formats."
        ]
    },
    {
        title: "Bilingual Support",
        icon: vision2,
        message: "",
        list: [
            "Communicate with patients in their preferred language to improve understanding and satisfaction, especially in diverse or multicultural markets.",
        ]
    },
    {
        title: "Google Business Profile Optimization",
        icon: service1,
        message: "",
        list: [
            "Update and manage your Google Business Profile during downtime.",
            "Optimize listings with accurate information, photos, and patient reviews to boost local SEO rankings and attract new patients.",
        ]
    },
    {
        title: "Patient Intake and Forms",
        icon: service2,
        message: "",
        list: [
            "Assist patients with completing intake forms online.",
            "Ensure all required documents are collected and uploaded before appointments.",
            "Streamline the onboarding process for new patients."
        ]
    },
    {
        title: "Recare and Recall Management",
        icon: service3,
        message: "",
        list: [
            "Follow up with patients who are overdue for cleanings, exams, or treatments.",
            "Encourage patients to stay consistent with preventive care visits."
        ]
    },
    {
        title: "Social Media and Marketing Support",
        icon: service4,
        message: "",
        list: [
            "Assist with creating and scheduling posts for social media platforms.",
            "Respond to messages and engage with followers to build your practice’s online presence.",
            "Help with email marketing campaigns to keep patients informed about promotions and updates."
        ]
    },
    {
        title: "Handling Referrals",
        icon: service5,
        message: "",
        list: [
            "Coordinate with other specialists for patient referrals.",
            "Track and manage referral data for seamless communication between offices."
        ]
    },
    {
        title: "Inventory Management Assistance",
        icon: storyImg2,
        message: "",
        list: [
            "Monitor dental supplies and alert the practice when stock is low.",
            "Place orders with suppliers to ensure uninterrupted operations."
        ]
    },
    {
        title: "Answering After-Hours Calls",
        icon: storyImg3,
        message: "",
        list: [
            "Provide extended coverage for patient inquiries and appointment requests beyond regular office hours.",
        ]
    },
    {
        title: "Staffing Flexibility",
        icon: storyImg4,
        message: "",
        list: [
            "Fill in administrative gaps during peak periods or staff absences.",
            "Handle overflow tasks without requiring additional office space or resources."
        ]
    },
    {
        title: "Analytics and Reporting",
        icon: storyImg5,
        message: "",
        list: [
            "Track and analyze patient data, appointment trends, and revenue insights.",
            "Provide reports to help the practice identify areas for improvement."
        ]
    },
    {
        title: "Training Customization",
        icon: service2,
        message: "",
        list: [
            "Receive tailored training based on your practice’s unique workflows, tools, and protocols to ensure maximum efficiency.",
        ]
    },
    {
        title: "Support for Specialized Practices",
        icon: service3,
        message: "",
        list: [
            "Assist with tasks specific to orthodontics, pediatrics, oral surgery, or cosmetic dentistry, ensuring a seamless experience across specialties.",
        ]
    },
    {
        title: "Quality Control for Patient Experience",
        icon: policy3,
        message: "",
        list: [
            "Monitor patient feedback and address concerns to enhance satisfaction.",
            "Actively contribute to creating a positive and welcoming practice culture."
        ]
    },
]

const whyusList = [
    {
        title: "Significant Cost Savings",
        icon: service1,
        message: "Honest Taskers helps dental practices save 65% or more on labor costs by hiring Virtual Dental Receptionists (VDRs). Without the added expenses of taxes, benefits, and paid time off, practices can significantly reduce overhead while still receiving high-quality, reliable support.",
        list: []
    },
    {
        title: "Access to Skilled Talent",
        icon: service2,
        message: "Honest Taskers provides highly trained VAs who excel in:",
        list: [
            "Insurance verification and claims processing",
            "Appointment scheduling and follow-ups",
            "Patient communication",
            "Google business profiles optimization and social media management",
            "Dental software proficiency, including Dentrix, Eaglesoft, and Open Dental"
        ]
    },
    {
        title: "Bilingual Support for Diverse Patient Bases",
        icon: service3,
        message: "For practices serving multilingual communities, Honest Taskers offers bilingual VAs, enhancing communication and patient satisfaction, especially in Spanish-speaking markets.",
        list: []
    },
    {
        title: "Reduced Turnover and Reliable Support",
        icon: service4,
        message: "High turnover is a common issue for in-office staff. Honest Taskers’ VAs are reliable, consistent, and have a proven track record of long-term commitment, eliminating the need for frequent retraining.",
        list: []
    },
    {
        title: "Enhanced Practice Efficiency",
        icon: service5,
        message: "Delegating administrative tasks to a VDR allows in-office staff to focus on clinical duties, streamlining operations and improving patient care.",
        list: []
    },
    {
        title: "Proactive and Versatile Services",
        icon: vision1,
        message: "Honest Taskers’ VAs go beyond traditional tasks. For example, during slow periods, they can optimize Google Business Profiles, improving local search rankings and attracting new patients.",
        list: []
    },
    {
        title: "Flexibility and Scalability",
        icon: vision2,
        message: "Honest Taskers’ VAs work the hours you need, including evenings or weekends, and require no physical office space. As your practice grows, you can easily scale your virtual team.",
        list: []
    },
    {
        title: "HIPAA-Compliant and Secure",
        icon: storyImg5,
        message: "All Honest Taskers VAs are trained in HIPAA compliance, ensuring patient data security and confidentiality.",
        list: []
    },
    {
        title: "Improved Patient Retention and Growth",
        icon: storyImg4,
        message: "Fewer missed calls, timely follow-ups, and excellent service lead to higher patient retention rates and increased new patient inquiries.",
        list: []
    },
    {
        title: "Proven Track Record",
        icon: storyImg3,
        message: "Dental practices using Honest Taskers’ VAs report improved efficiency, reduced costs, and measurable growth, such as increased bookings and fewer no-shows.",
        list: []
    },
    {
        title: "Two-Week Trial to Ensure the Perfect Fit",
        icon: policy2,
        message: "Honest Taskers offers a two-week trial so you can assess whether the VDR meets your practice’s unique needs before committing.",
        list: []
    },
    {
        title: "No Long-Term Commitment",
        icon: policy5,
        message: "With no long-term contracts, Honest Taskers provides flexibility to adapt to your practice’s evolving needs.",
        list: []
    },
    {
        title: "Core Values-Driven Hiring",
        icon: policy8,
        message: "Honest Taskers doesn’t just hire for talent; they prioritize core values that align with their dental partners, ensuring a positive impact on your team culture and patient interactions.",
        list: []
    },
    {
        title: "Customized Training for Unique Practice Needs",
        icon: policy7,
        message: "If your practice has specific requirements, Honest Taskers offers customized training to ensure their VAs are fully prepared to meet your unique needs. This tailored approach helps optimize performance and achieve the desired outcomes for your practice.",
        list: []
    },
]

const faqs = [
    {
        question: "What is the cost for your Virtual Dental Receptionist staffing services?",
        answer: "Our rates start at $10/hour and vary based on the experience and location of the Virtual Dental Receptionist (VDR). We work with you to match your budget and requirements, ensuring you get the best value for your investment."
    },
    {
        question: "What tasks can the Virtual Dental Receptionist handle for my practice?",
        answer: "Our Virtual Dental Receptionists (VDRs) can manage a wide range of tasks, including:",
        options: [
            "Appointment scheduling and confirmation",
            "Patient intake and record updates",
            "Insurance verification and claims processing",
            "Patient communication, such as reminders and inquiries",
            "Billing and payment follow-ups",
            "Treatment plan presentations",
            "Managing Google Business Profiles during downtime",
            "Social Media management",
            "Supporting compliance documentation",
            "Recare and Recall Management",
            "Handling referrals",
            "etc"
        ]
    },
    {
        question: "How experienced is the Virtual Dental Receptionists in dental-specific tasks?",
        answer: "Our VDRs are highly experienced and trained in dental-specific tasks. They are familiar with dental terminology, processes, and popular software like Dentrix, Eaglesoft, and Open Dental. Many have prior experience in dental settings, ensuring they can handle tasks efficiently."
    },
    {
        question: "Are they HIPAA-compliant, and how is patient data security ensured?",
        answer: "Yes, all Honest Taskers Virtual Dental Receptionists are HIPAA-trained and compliant. We use secure, encrypted communication channels and access controls to safeguard patient data. Regular training and audits ensure continued compliance."
    },
    {
        question: "What hours of availability can they provide?",
        answer: "Our VDRs work flexible hours to accommodate your practice’s needs, including evenings, weekends, and during peak times. We ensure their schedules align with your time zone and operational requirements."
    },
    {
        question: "Can the Virtual Dental Receptionist assist with patient communication?",
        answer: "Absolutely. Our VDRs excel in patient communication, including:",
        options: [
            "Answering phone calls",
            "Sending appointment reminders",
            "Managing patient inquiries through email or chat",
            "Handling follow-ups to improve satisfaction and retention"
        ]
    },
    {
        question: "What kind of training and onboarding is required for the Virtual Dental Receptionist?",
        answer: "Honest Taskers provides initial training tailored to general dental tasks. If your practice has specific protocols, we offer customized training to ensure seamless integration. Onboarding typically takes 1-2 weeks."
    },
    {
        question: "What dental software platforms are they proficient in?",
        answer: "Our VDRs are trained in popular dental software, including:",
        options: [
            "Dentrix",
            "Eaglesoft",
            "Open Dental",
            "SoftDent",
            "ClearDent",
            "We can also provide training for other tools your practice uses."
        ]
    },
    {
        question: "How is their performance monitored and evaluated?",
        answer: "We track VDR performance through productivity reports, regular check-ins, and feedback from your practice. Our monitoring includes adherence to assigned tasks, timely responses, and compliance with protocols. Any concerns are addressed promptly."
    },
    {
        question: "What communication tools do they use to stay in touch with the team?",
        answer: "Our VDRs use secure and efficient platforms like Slack, Microsoft Teams, Zoom, and HIPAA-compliant email systems to ensure seamless collaboration with your team."
    },
    {
        question: "What is the cost structure, and are there any long-term contracts?",
        answer: "Our pricing is flexible and based on your needs, with options for hourly or flat-rate packages. There are no long-term contracts, giving you the freedom to scale or adjust as necessary."
    },
    {
        question: "Can they handle dental insurance verification and claims processing?",
        answer: "Yes, our VDRs specialize in insurance-related tasks, including verifying patient benefits, submitting claims, and following up on denials or pending claims."
    },
    {
        question: "How do they handle patient complaints or difficult conversations?",
        answer: "Our VDRs are trained in professional communication and problem-solving. They handle complaints empathetically and escalate issues to the appropriate team member when necessary."
    },
    {
        question: "What languages do they speak fluently?",
        answer: "Many of our VDRs are bilingual, with fluency in English and other languages such as Spanish. This ensures better communication with diverse patient bases."
    },
    {
        question: "Can they assist with social media management or marketing tasks?",
        answer: "Yes, our VDRs can support marketing by:",
        options: [
            "Creating and scheduling posts for social media",
            "Responding to messages",
            "Managing your Google Business Profile to improve local SEO"
        ]
    },
    {
        question: "How do they ensure accuracy in documenting patient records?",
        answer: "Our VDRs follow strict protocols for accuracy and compliance when entering patient data. They double-check entries and adhere to HIPAA guidelines to maintain record integrity."
    },
    {
        question: "What tools or technology do they need from the practice?",
        answer: "Typically, our VDRs need access to:",
        options: [
            "Practice management software (e.g., Dentrix)",
            "Secure communication tools",
            "Real time communication chat ",
            "Web phone ",
            "Remote access systems (if required)",
            "We can guide you on setup during onboarding."
        ]
    },
    {
        question: "Do they have experience working in a fast-paced dental practice?",
        answer: "Yes, many of our VDRs have worked in busy practices and are skilled at handling high patient volumes, managing schedules efficiently, and staying organized under pressure."
    },
    {
        question: "How quickly can they adapt to our office workflows and protocols?",
        answer: "Our VDRs are quick learners and typically adapt to your workflows within 2 -12 weeks. Customized training during onboarding ensures they are ready to hit the ground running."
    },
    {
        question: "What happens if they are unavailable or have connectivity issues?",
        answer: "Honest Taskers ensures continuity with backup staff or quick replacements in case of emergencies. We also require our VAs to work from reliable, secure environments with strong internet connections and have back up incase of disruption"
    },
    {
        question: "Can they assist with maintaining compliance documentation?",
        answer: "Yes, our VDRs can help manage and update compliance-related documents, such as OSHA records, patient forms, and audit logs, ensuring your practice stays compliant."
    },
    {
        question: "Where are your Virtual Dental Receptionists located?",
        answer: "Our Virtual Dental Receptionists are based in the Philippines and Latin America."
    }
]


const reviews = [
    {
        name: "Dr. Michael Anderson",
        initials: "MA",
        message: "I was initially looking for a Dental Virtual Admin Receptionist to handle the usual tasks—phone calls, insurance verification, appointment scheduling, and patient communication. Honest Taskers delivered exactly that, but what really surprised me was how proactive our VA has been during slower times at the practice. Instead of staying idle, they’ve taken the initiative to optimize our Google Business Profile. This effort has significantly improved our local Google rankings, and we’re now seeing a noticeable increase in new patient inquiries. The investment has more than paid for itself, and with the results we’re seeing, we’re already planning to expand our Honest Taskers VA team within the next two months!"
    },
    {
        name: "Dr. Amanda Carter",
        initials: "AC",
        message: "Having a single admin staff member in our office was costing us over $27/hour once we factored in taxes, paid time off, and other benefits. On top of that, the demands for higher pay made it increasingly difficult to sustain our bottom line. For the same cost, I was able to hire not one but two exceptional dental Virtual Receptionists (VAs) from Honest Taskers. The cost savings have been significant, but beyond affordability, their performance has been outstanding. They’ve been a fantastic addition to our practice, and I wouldn’t have it any other way!"
    },
    {
        name: "Dr. Elena Ramirez",
        initials: "ER",
        message: "As a practice in a market that is 80% Latino, finding a bilingual receptionist was a top priority for us. Honest Taskers connected us with an incredible virtual dental admin assistant based in Nicaragua, and the results have been amazing. Since she joined our team, our bookings have increased by 17%, and missed calls have dropped by over 90%. But it’s not just about the numbers—our patients love her! She’s friendly, professional, and speaks to them in their preferred language, which has made a huge difference in how they feel about our practice. I couldn’t be happier with this decision, and I only wish I had done it sooner!"
    },
    {
        name: "Dr. Michael Anderson",
        initials: "MA",
        message: "I was initially looking for a Dental Virtual Admin Receptionist to handle the usual tasks—phone calls, insurance verification, appointment scheduling, and patient communication. Honest Taskers delivered exactly that, but what really surprised me was how proactive our VA has been during slower times at the practice. Instead of staying idle, they’ve taken the initiative to optimize our Google Business Profile. This effort has significantly improved our local Google rankings, and we’re now seeing a noticeable increase in new patient inquiries. The investment has more than paid for itself, and with the results we’re seeing, we’re already planning to expand our Honest Taskers VA team within the next two months!"
    },
    {
        name: "Dr. Sarah Thompson",
        initials: "ST",
        message: "Before partnering with Honest Taskers, we struggled with the high turnover rate of our practice admin staff. It was frustrating and disruptive to our operations. Then we decided to give a Virtual Dental Receptionist a try—and I’m so glad we did! Our Dental VA from Honest Taskers has been with us for over a year now, and she hasn’t missed a single day of work. I was initially skeptical about whether the concept of a virtual assistant would work for our practice, but she has exceeded all expectations. She’s an incredible part of our team, and we honestly can’t imagine running the practice without her."
    }
]
const howItWorks = [
    {
        title: "Submit Inquiry:",
        img: dutyImg1,
        message: "Let us know your needs by submitting an inquiry. Provide details about the tasks and skills you’re looking for in a Virtual Dental Receptionist (VDR)."
    },
    {
        title: "Discovery Call:",
        img: dutyImg2,
        message: "We’ll schedule a discovery call with your team to discuss your specific requirements, clarify expectations, and answer any questions you have about our process."
    },
    {
        title: "Interview Selected Candidates:",
        img: dutyImg3,
        message: "Based on your needs, we’ll present a shortlist of qualified candidates. You’ll interview your hand-selected candidates to ensure they meet your practice’s unique requirements."
    },
    {
        title: "Start Working With Your VDR:",
        img: dutyImg4,
        message: "Once you select your candidate, they’ll start working with your practice, delivering immediate value and support tailored to your needs."
    }
]

const hippas = [
    {
        img: feature1,
        title: "Comprehensive Training for Virtual Dental Receptionist",
        message: "All Virtual Dental Receptionists (VDRs) receive extensive training on HIPAA regulations, including:",
        options: [
            "Proper handling of Protected Health Information (PHI).",
            "Identifying and mitigating risks of data breaches.",
            "Secure communication practices to prevent unauthorized access."
        ]
    },
    {
        img: feature2,
        title: "Secure Communication Channels",
        message: "We ensure that all communication between VDRs and your practice is conducted via HIPAA-compliant platforms, such as:",
        options: [
            "Encrypted email services.",
            "Secure file-sharing systems.",
            "HIPAA-compliant phone and video conferencing tools."
        ]
    },
    {
        img: feature3,
        title: "Access Control and Authorization",
        options: [
            "VDRs are granted access only to the information necessary to perform their duties.",
            "Role-based permissions ensure that sensitive data is restricted to authorized personnel."
        ]
    },
    {
        img: feature1,
        title: "Data Encryption",
        message: "All transmitted and stored data is encrypted to safeguard it from potential breaches or unauthorized access."
    },
    {
        img: feature4,
        title: "Regular Audits and Monitoring",
        message: "Honest Taskers conducts routine internal audits to ensure ongoing compliance with HIPAA standards and proactively address potential vulnerabilities."
    },
    {
        img: feature5,
        title: "Business Associate Agreements (BAA)",
        message: "We provide Business Associate Agreements to all dental practices, ensuring a clear understanding of responsibilities and compliance requirements."
    },
    {
        img: feature6,
        title: "Secure Work Environment for VDRs",
        options: [
            "VDRs work in private, monitored environments to prevent unauthorized viewing or sharing of PHI.",
            "Policies are in place to prevent the use of unsecured devices or networks."
        ]
    },
    {
        img: feature1,
        title: "Incident Management Protocols",
        message: "In the rare event of a security incident, Honest Taskers has a well-defined protocol to:",
        options: [
            "Immediately contain and resolve the issue.",
            "Notify your practice promptly.",
            "Take corrective actions to prevent recurrence."
        ]
    },
    {
        img: feature1,
        title: "Continuous Education",
        message: "VDRs participate in ongoing HIPAA training to stay updated on evolving regulations and best practices for maintaining data security."
    },
]
import React, { useState } from "react";
import './style.scss';
import { AddExperienceModal, ExperienceComponent } from "./Experience";
import { useDispatch, useSelector } from "react-redux";
import { toggleCertificationModalAction, toggleExperienceModalAction, toggleSkillModalAction } from "src/Redux/Actions";
import { AddCertificationModal, CertificationComponent } from "./Certificates";
import { AddSkillModal, SkillsComponent } from "./Skills";
import { ToolsComponent } from "./Tools";
import { RolesComponent } from "./Roles";
import SubmitArea from "../SubmitArea";

export default function Qualification() {
    const tabs = ['Experience', 'Certifications', 'Skills', 'Tools', 'Roles'];
    const [currentTab, setCurrentTab] = useState(tabs[0])
    const dispatch = useDispatch()
    const {
        showExperienceModal,
        showCertificationModal,
        showSkillModal
    } = useSelector(state => state.Signup)

    function TabContents() {
        switch (currentTab) {
            case 'Experience':
                return (<ExperienceComponent />)
            case 'Certifications':
                return (<CertificationComponent />)
            case 'Skills':
                return (<SkillsComponent />)
            case 'Tools':
                return (<ToolsComponent />)
            case 'Roles':
                return (<RolesComponent />)
            default: 
                return (<ExperienceComponent />)
        }
    }

    return (
        <div className="qualification">
            <div className="tab-wrapper">
                <div className="tabs">
                    {
                        tabs.map((item, index) => (
                            <div className={`tab-item ${currentTab == item ? 'active' : ''}`} key={index} onClick={() => setCurrentTab(item)}>{item}</div>
                        ))
                    }
                </div>
            </div>
            <div className="tab-contents">
                <TabContents />
                <AddExperienceModal show={showExperienceModal} onHide={() => dispatch(toggleExperienceModalAction(false))}/>
                <AddCertificationModal show={showCertificationModal} onHide={() => dispatch(toggleCertificationModalAction(false))}/>
                <AddSkillModal show={showSkillModal} onHide={() => dispatch(toggleSkillModalAction(false))}/>
            </div>
            <SubmitArea onContinue={() => {}} onBack={() => {}}/>
        </div>
    )
}
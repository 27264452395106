import React, { useState } from "react";
import Select from 'react-select';

export default function SelectComponent({value, options, multiple=false, searchable=true, onChange, placeholder = "Search..."}) {
  
    return (
        <Select
          defaultValue={value}
          placeholder={placeholder}
          onChange={onChange}
          options={options}
          isSearchable={searchable}
          isMulti={multiple}
          className="custom-select"
        />
    );
  }
import { useState } from "react"
import { InputComponent } from "src/Components/Common"
import SubmitArea from "../SubmitArea"


export default function RemoteWorkReadiness() {
     const [url, setUrl] = useState(null)

    return (
        <div className="remote-work">
            <h1>Work from Home Readiness</h1>
            <p className="mt-5">To ensure you are prepared for a remote work environment, please submit the following:</p>
            <ul style={{listStyleType:"decimal"}}>
                <li>A photo of your home workspace.</li>
                <li>A screenshot of your internet speed.</li>
                <li>A screenshot of your computer specifications.</li>
                <li>A photo showing your backup power supply and internet setup.</li>
                <li>A photo of your work computer</li>
                <li>Front and back of government issued ID Card or Passport for Identity verification</li>
            </ul>
            <div style={{fontWeight:"bold",marginTop:"3rem"}}>Note:</div>
            <p>Upload all documents to a google drive or dropbox folder and submit the link to the folder below. </p>
            <div className="mt-5 mb-2" style={{fontSize:"20px",fontWeight:"bold"}}>Enter folder link here</div>
            <InputComponent value={url} onChange={(value) => setUrl(value)}/>
            <SubmitArea onContinue={() => {}} onBack={() => {}}/>
        </div>
    )
}
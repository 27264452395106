import { AdwordsFAQs, AdwordsHeroSection, AdwordsHowItWorks, AdwordsReviews, AdwordsServices, AdwordsWhyUs, BrandingSection, FooterBottom, FreeTrialSection, HIPAACompliance, Pricing, RequestDiscoveryCall } from "src/Components";
import CallToAction from "src/Components/Adwords/CallToAction";
import Showcase from "src/Components/Adwords/Showcase";
import { getPricingPlans } from "src/Constant";
import dutyImg1 from 'src/Assets/Images/VmrHome/Duty1.svg';
import dutyImg2 from 'src/Assets/Images/VmrHome/Duty2.svg';
import dutyImg3 from 'src/Assets/Images/VmrHome/Duty3.svg';
import dutyImg4 from 'src/Assets/Images/VmrHome/Duty4.svg';
import feature1 from 'src/Assets/Images/VmsHome/Feature1.svg';
import feature2 from 'src/Assets/Images/VmsHome/Feature2.svg';
import feature3 from 'src/Assets/Images/VmsHome/Feature3.svg';
import feature4 from 'src/Assets/Images/VmsHome/Feature4.svg';
import feature5 from 'src/Assets/Images/VmsHome/Feature5.svg';
import feature6 from 'src/Assets/Images/VmsHome/Feature6.svg';
import storyImg3 from 'src/Assets/Images/Story/Story6.svg';
import storyImg4 from 'src/Assets/Images/Home/FaqHImg.svg';
import storyImg5 from 'src/Assets/Images/Home/WhyHImg.svg';
import policy2 from 'src/Assets/Images/Policy/P2.svg';
import policy5 from 'src/Assets/Images/Policy/P5.svg';
import policy7 from 'src/Assets/Images/Policy/P7.svg';
import policy8 from 'src/Assets/Images/Policy/P8.svg';
import service1 from 'src/Assets/Images/Services/Services1.svg';
import service2 from 'src/Assets/Images/Services/Services2.svg';
import service3 from 'src/Assets/Images/Services/Services3.svg';
import service4 from 'src/Assets/Images/Services/Services4.svg';
import service5 from 'src/Assets/Images/Services/Services5.svg';
import vision1 from 'src/Assets/Images/Vision/Vision1.svg';
import vision2 from 'src/Assets/Images/Vision/Vision2.svg';


export default function VMH_AdwordsPage() {

    const heroTitle = "Do You Need An Experience Virtual Mental Health Assistant To Support Your Practice and Save 70% On Labor Costs?";
    const howItWorksTitle = "This entire process is streamlined and typically takes <b>1–2 weeks</b>, allowing you to quickly integrate a skilled Virtual Medical Assistant into your team.";
    const requestTitle = "Get More Information About Our Virtual Mental Health Assistant Staffing Services";
    const requestSubTitle = "We pride ourselves on having a warm and approachable team. You’ll love talking to us as we guide you through finding the perfect Virtual Mental Health Assistant for your practice!";
    return ( 
        <div className="vdr-landing">
           <AdwordsHeroSection title={heroTitle}/>
           <BrandingSection />
           <AdwordsReviews reviews={reviews} title="Our Medical Practice Owners and Managers Love Us As Much As We Love Them"/>
           <Showcase titleVA="Who Are Our Virtual Mental Health Assistants?" titleClient="What Our Clients Have To Say About Their Medical VA"/>
           {/* <CallToAction /> */}
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Mental Health Assistant Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <AdwordsWhyUs title={"Why Hire Our Virtual Mental Health Assistant"} whyusList={whyusList}/>
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Mental Health Assistant Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <AdwordsServices title={"Ways Our Virtual Mental Health Assistant Can Support Your Practice"} services={services}/>
           <AdwordsHowItWorks howItWorks={howItWorks} title={howItWorksTitle}/>
           <HIPAACompliance hippas={hippas}/>
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Mental Health Assistant Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <Pricing plans={getPricingPlans('pricing-one')}/>
           <AdwordsFAQs faqs={faqs}/>
           <RequestDiscoveryCall title={requestTitle} subTitle={requestSubTitle}/>
           <FooterBottom />
        </div>
    )
}

const reviews = [
    {
        name: "Dr. Rebecca Hughes",
        initials: "RH",
        subTitle: "Licensed Therapist",
        message: "As a therapist, managing administrative tasks used to take up so much of my time and energy, leaving less room to focus on my clients. Since hiring a Mental Health Virtual Assistant from Honest Taskers, my practice has become more organized and efficient. My VA handles scheduling, patient intakes, insurance verification, and follow-up communications with ease. They also assist with billing and maintaining patient records, which has made my day-to-day operations much smoother. The professionalism and reliability of my VA have made a significant difference in how I manage my workload. Honest Taskers has been an invaluable resource for my practice."
    },
    {
        name: "Dr. Sarah Mitchell",
        initials: "SM",
        subTitle: "Practice Owner",
        message: "As a practice manager, I’ve worked with Virtual Medical Assistants from other companies before, but the quality of Honest Taskers' Virtual Medical Assistants is truly unmatched. We now have four Virtual Medical Assistants from Honest Taskers, and their support has reduced our need for in-person Medical Assistants by 40%. Our Virtual Medical Assistants conduct live patient intakes via Zoom with professionalism and warmth, and our patients absolutely love them. They’ve become such an integral part of our team that we even have their pictures on the wall with the rest of our staff! Honest Taskers has completely transformed our operations, and I couldn’t recommend them enough."
    },
    {
        name: " Lynn Roberts",
        initials: "LR",
        subTitle: "Practice Manager, Together Women’s Health",
        message: "Our Virtual Medical Assistant from Honest Taskers has been a game-changer for our practice. She seamlessly handles a variety of administrative tasks, including patient scheduling, insurance verification, patient intaket, and follow-up communications. Her efficiency and attention to detail have significantly reduced our team's workload, allowing us to focus more on patient care. What truly stands out is her professionalism and ability to adapt to our workflow. She has become an integral part of our team, and we can’t imagine running the practice without her support. Honest Taskers has exceeded our expectations!"
    },
    {
        name: "Dr. Mark Stevens",
        subTitle: "Psychiatrist",
        initials: "MS",
        message: "As a psychiatrist running a telemedicine-only practice, the Virtual Medical Assistant from Honest Taskers has been absolutely essential. Since I don’t have any in-person staff, my Virtual Medical Assistant handles all the administrative tasks—patient scheduling, insurance verification, billing, follow-ups, and more. Her efficiency and reliability allow me to focus 100% on patient care without worrying about the day-to-day operations of the practice. She truly runs the administrative side of the practice seamlessly, and I couldn’t do this without her. Honest Taskers has been a game-changer for my telemedicine practice!"
    }
]

const whyusList = [
    {
        title: "Significant Cost Savings",
        icon: service1,
        message: "Honest Taskers helps dental practices save 65% or more on labor costs by hiring Virtual Medical Assistant (VMAs). Without the added expenses of taxes, benefits, and paid time off, practices can significantly reduce overhead while still receiving high-quality, reliable support.",
        list: []
    },
    {
        title: "Access to Skilled Talent",
        icon: service2,
        message: "Honest Taskers provides highly trained VAs who excel in:",
        list: [
            "Insurance verification and claims processing",
            "Appointment scheduling and follow-ups",
            "Patient communication",
            "Google business profiles optimization and social media management",
            "EMR software proficiency, Tebra, DrChrono, athenahealth, eClinicalWorks, Practice Fusion, AdvanceMD and more"
        ]
    },
    {
        title: "Bilingual Support for Diverse Patient Bases",
        icon: service3,
        message: "For practices serving multilingual communities, Honest Taskers offers bilingual VAs, enhancing communication and patient satisfaction, especially in Spanish-speaking markets.",
        list: []
    },
    {
        title: "Reduced Turnover and Reliable Support",
        icon: service4,
        message: "High turnover is a common issue for in-office staff. Honest Taskers’ VAs are reliable, consistent, and have a proven track record of long-term commitment, eliminating the need for frequent retraining.",
        list: []
    },
    {
        title: "Enhanced Practice Efficiency",
        icon: service5,
        message: "Delegating administrative tasks to a VMA allows in-office staff to focus on clinical duties, streamlining operations and improving patient care.",
        list: []
    },
    {
        title: "Proactive and Versatile Services",
        icon: vision1,
        message: "Honest Taskers’ VAs go beyond traditional tasks. For example, during slow periods, they can optimize Google Business Profiles, improving local search rankings and attracting new patients.",
        list: []
    },
    {
        title: "Flexibility and Scalability",
        icon: vision2,
        message: "Honest Taskers’ VAs work the hours you need, including evenings or weekends, and require no physical office space. As your practice grows, you can easily scale your virtual team.",
        list: []
    },
    {
        title: "HIPAA-Compliant and Secure",
        icon: storyImg5,
        message: "All Honest Taskers VAs are trained in HIPAA compliance, ensuring patient data security and confidentiality.",
        list: []
    },
    {
        title: "Improved Patient Retention and Growth",
        icon: storyImg4,
        message: "Fewer missed calls, timely follow-ups, and excellent service lead to higher patient retention rates and increased new patient inquiries.",
        list: []
    },
    {
        title: "Proven Track Record",
        icon: storyImg3,
        message: "Dental practices using Honest Taskers’ VAs report improved efficiency, reduced costs, and measurable growth, such as increased bookings and fewer no-shows.",
        list: []
    },
    {
        title: "Two-Week Trial to Ensure the Perfect Fit",
        icon: policy2,
        message: "Honest Taskers offers a two-week trial so you can assess whether the VMA meets your practice’s unique needs before committing.",
        list: []
    },
    {
        title: "No Long-Term Commitment",
        icon: policy5,
        message: "With no long-term contracts, Honest Taskers provides flexibility to adapt to your practice’s evolving needs.",
        list: []
    },
    {
        title: "Core Values-Driven Hiring",
        icon: policy8,
        message: "Honest Taskers doesn’t just hire for talent; they prioritize core values that align with their dental partners, ensuring a positive impact on your team culture and patient interactions.",
        list: []
    },
    {
        title: "Customized Training for Unique Practice Needs",
        icon: policy7,
        message: "If your practice has specific requirements, Honest Taskers offers customized training to ensure their VAs are fully prepared to meet your unique needs. This tailored approach helps optimize performance and achieve the desired outcomes for your practice.",
        list: []
    },
]

const faqs = [
    {
        question: "What tasks can the Virtual Medical Assistant handle for my practice?",
        answer: "Our Virtual Medical Assistant (VMAs) can manage a wide range of tasks, including:",
        options: [
            "Appointment scheduling and confirmation",
            "Patient intake and record updates",
            "Insurance verification and claims processing",
            "Patient communication, such as reminders and inquiries",
            "Billing, coding, and payment follow-ups",
            "Prescription refills and referrals",
            "Scribing and chart preparation",
            "Managing Google Business Profiles and social media",
            "Compliance documentation and reporting",
            "Care coordination and remote patient monitoring"
        ]
    },
    {
        question: "How experienced is the Virtual Medical Assistant in healthcare-specific tasks?",
        answer: "Our VMAs are highly trained and experienced in healthcare-specific administrative and clinical tasks. They are familiar with medical terminology, processes, and popular EHR systems like Athenahealth, Kareo, and eClinicalWorks."
    },
    {
        question: "Are they HIPAA-compliant, and how is patient data security ensured?",
        answer: "Yes, all Honest Taskers VMAs are HIPAA-trained and compliant. We use secure, encrypted communication channels and access controls to safeguard patient data. Regular training and audits ensure continued compliance."
    },
    {
        question: "What is the educational background of your Virtual Medical Assistant?",
        answer: "Our Virtual Medical Assistant (VMAs) have a strong educational background, including bachelor’s degrees in nursing, healthcare, or related fields, and some even hold degrees in medicine. Many are registered nurses (RNs) or have formal training in medical-related disciplines, making them adept at handling both clinical and administrative tasks. Additionally, some have specialized certifications in medical coding, billing, or transcription, further enhancing their skill set. Combined with extensive training in medical terminology, HIPAA compliance, and practice management software, our VMAs are highly qualified to support the diverse needs of medical practices."
    },
    {
        question: "What hours of availability can they provide?",
        answer: "Our VMAs work flexible hours to accommodate your practice’s needs, including evenings, weekends, and during peak times. We ensure their schedules align with your time zone and operational requirements."
    },
    {
        question: "Can the Virtual Dental Assistant assist with patient communication?",
        answer: "Absolutely. Our VMAs excel in patient communication, including:",
        options: [
            "Answering phone calls",
            "Sending appointment reminders",
            "Managing patient inquiries through email or chat",
            "Handling follow-ups to improve satisfaction and retention"
        ]
    },
    {
        question: "What kind of training and onboarding is required for the Medical Assistant ?",
        answer: "Honest Taskers provides initial training tailored to general dental tasks. If your practice has specific protocols, we offer customized training to ensure seamless integration. Onboarding typically takes 1-2 weeks."
    },
    {
        question: "What software platforms are they proficient in?",
        answer: "Our VMAs are trained in popular medical software, including:",
        options: [
            "Athenahealth",
            "Kareo",
            "eClinicalWorks",
            "Epic",
            "AdvancedMD",
            "ETC",
            "If your practice uses other tools, we can provide additional training."
        ]
    },
    {
        question: "How is their performance monitored and evaluated?",
        answer: "We track VMA performance through productivity reports, regular check-ins, and feedback from your practice. Our monitoring includes adherence to assigned tasks, timely responses, and compliance with protocols. Any concerns are addressed promptly."
    },
    {
        question: "What communication tools do they use to stay in touch with the team?",
        answer: "Our VMAs use secure and efficient platforms like Slack, Microsoft Teams, Zoom, and HIPAA-compliant email systems to ensure seamless collaboration with your team."
    },
    {
        question: "What is the cost structure, and are there any long-term contracts?",
        answer: "Our pricing is flexible and based on your needs, with options for hourly or flat-rate packages. There are no long-term contracts, giving you the freedom to scale or adjust as necessary."
    },
    {
        question: "Can they handle insurance verification and claims processing?",
        answer: "Yes, our VMAs specialize in insurance-related tasks, including verifying patient benefits, submitting claims, and following up on denials or pending claims."
    },
    {
        question: "How do they handle patient complaints or difficult conversations?",
        answer: "Our VMAs are trained in professional communication and problem-solving. They handle complaints empathetically and escalate issues to the appropriate team member when necessary."
    },
    {
        question: "What languages do they speak fluently?",
        answer: "Many of our VMAs are bilingual, with fluency in English and other languages such as Spanish. This ensures better communication with diverse patient bases."
    },
    {
        question: "Can they assist with social media management or marketing tasks?",
        answer: "Yes, our VMAs can support marketing by:",
        options: [
            "Creating and scheduling posts for social media",
            "Managing social media accounts",
            "Responding to online inquiries",
            "Optimizing Google Business Profiles for better local SEO"
        ]
    },
    {
        question: "How do they ensure accuracy in documenting patient records?",
        answer: "Our VMAs follow strict protocols for accuracy and compliance when entering patient data. They double-check entries and adhere to HIPAA guidelines to maintain record integrity."
    },
    {
        question: "What tools or technology do they need from the practice?",
        answer: "Typically, our VMAs require:",
        options: [
            "Access to your EHR system",
            "Secure communication tools",
            "A web phone or VOIP system",
            "Remote access to relevant files or systems"
        ]
    },
    {
        question: "Do they have experience working in a fast-paced medical practice?",
        answer: "Yes, many of our VMAs have worked in busy practices and are skilled at handling high patient volumes, managing schedules efficiently, and staying organized under pressure."
    },
    {
        question: "How quickly can they adapt to our office workflows and protocols?",
        answer: "Our VMAs are quick learners and typically adapt to your workflows within 2 -12 weeks. Customized training during onboarding ensures they are ready to hit the ground running."
    },
    {
        question: "What happens if they are unavailable or have connectivity issues?",
        answer: "Honest Taskers ensures continuity with backup staff or quick replacements in case of emergencies. We also require our VAs to work from reliable, secure environments with strong internet connections and have back up incase of disruption"
    },
    {
        question: "Can they assist with maintaining compliance documentation?",
        answer: "Yes, our VMAs can help manage and update compliance-related documents, such as OSHA records, patient forms, and audit logs, ensuring your practice stays compliant."
    },
    {
        question: "Where are your Virtual Medical Assistants located?",
        answer: "Our Virtual Dental Assistant are based in the Philippines and Latin America."
    }
]

const services = [
    {
        title: "Real-Time Documentation",
        icon: dutyImg1,
        message: "",
        list: [
            "Accurately document patient encounters during consultations.",
            "Enter notes directly into the Electronic Health Record (EHR) system in real-time.",
        ]
    },
    {
        title: "EHR Management",
        icon: dutyImg2,
        message: "",
        list: [
            "Update patient records with new information, test results, and treatment plans.",
            "Ensure all documentation complies with regulatory standards and is organized for easy retrieval.",
        ]
    },
    {
        title: "Chart Preparation",
        icon: dutyImg3,
        message: "",
        list: [
            "Prepare patient charts before appointments by summarizing previous visits.",
            "Highlight important medical history and notes for the provider's review.",
        ]
    },
    {
        title: "Human Editing for AI-Generated Transcripts",
        icon: dutyImg4,
        message: "",
        list: [
            "Review and correct AI-generated medical transcripts for accuracy and completeness.",
            "Ensure transcripts adhere to medical documentation standards and remove errors or ambiguities.",
        ]
    },
    {
        title: "Transcription Services",
        icon: vision1,
        message: "",
        list: [
            "Provide high-quality transcription for dictated notes, patient encounters, and medical reports.",
            "Convert voice recordings into structured and organized EHR entries.",
        ]
    },
    {
        title: "Medical Coding Support",
        icon: vision2,
        message: "",
        list: [
            "Assist in selecting appropriate CPT and ICD-10 codes based on documentation.",
            "Ensure accurate coding to optimize billing and reduce claim denials."
        ]
    },
    {
        title: "Order Entry Assistance",
        icon: vision2,
        message: "",
        list: [
            "Enter orders for lab tests, imaging studies, and prescriptions as directed by the provider.",
            "Track orders to ensure they are completed and results are received promptly."
        ]
    },
    {
        title: "Workflow Optimizatione",
        icon: dutyImg1,
        message: "",
        list: [
            "Streamline administrative tasks to allow providers to focus more on patient care.",
            "Reduce the time providers spend on documentation, improving efficiency."
        ]
    },
    {
        title: "Quality Assurance",
        icon: dutyImg1,
        message: "",
        list: [
            "Review notes for completeness and accuracy.",
            "Ensure all necessary components of the patient encounter are documented."
        ]
    },
    {
        title: "Compliance and Regulatory Support",
        icon: dutyImg1,
        message: "",
        list: [
            "Stay updated on documentation guidelines and compliance requirements.",
            "Help maintain HIPAA compliance by ensuring patient data is handled securely."
        ]
    },
    {
        title: "Telehealth Support",
        icon: dutyImg1,
        message: "",
        list: [
            "Assist with documenting virtual consultations during telemedicine visits.",
            "Manage technical aspects to ensure smooth virtual patient encounters."
        ]
    },
    {
        title: "Patient Follow-Up Documentation",
        icon: dutyImg1,
        message: "",
        list: [
            "Document follow-up calls and patient communications.",
            "Update patient records with new information obtained after initial visits."
        ]
    },
    {
        title: "Template Creation and Management",
        icon: dutyImg1,
        message: "",
        list: [
            "Develop and manage templates within the EHR to standardize documentation.",
            "Customize templates to fit the provider's specific needs and specialties."
        ]
    },
    {
        title: "Time Management",
        icon: dutyImg1,
        message: "",
        list: [
            "Help providers see more patients by handling time-consuming documentation tasks.",
            "Allow providers to leave on time without the burden of after-hours charting."
        ]
    },
    {
        title: "Communication Facilitation",
        icon: dutyImg1,
        message: "",
        list: [
            "Act as a liaison between the provider and other staff for documentation-related queries.",
            "Clarify any uncertainties in patient information during or after consultations."
        ]
    },
    {
        title: "Training and Adaptability",
        icon: dutyImg1,
        message: "",
        list: [
            "Quickly adapt to your practice's specific workflows and documentation styles.",
            "Undergo training on your EHR system and stay updated with any changes."
        ]
    },
    {
        title: "Multi-Specialty Expertise",
        icon: dutyImg1,
        message: "",
        list: [
            "Possess knowledge across various specialties, understanding specific terminology and protocols.",
            "Tailor documentation practices to suit different medical fields within your practice."
        ]
    },
    {
        title: "Data Accuracy Improvement",
        icon: dutyImg1,
        message: "",
        list: [
            "Enhance the accuracy of patient records, reducing errors that could affect patient care.",
            "Ensure detailed and precise documentation for better clinical decision-making."
        ]
    },
    {
        title: "Provider Burnout Reduction",
        icon: dutyImg1,
        message: "",
        list: [
            "Alleviate the administrative burden on providers, reducing stress and burnout.",
            "Improve job satisfaction by allowing providers to focus on patient interactions."
        ]
    },
    {
        title: "Cost-Effective Solution",
        icon: dutyImg1,
        message: "",
        list: [
            "Provide high-quality scribing services at a fraction of the cost of in-person scribes.",
            "Reduce overhead expenses associated with hiring and training in-house staff."
        ]
    },
    {
        title: "Legal Protection",
        icon: dutyImg1,
        message: "",
        list: [
            "Ensure thorough and compliant documentation to protect the practice during audits or legal proceedings.",
            "Maintain accurate records that reflect the standard of care provided."
        ]
    },
    {
        title: "Patient Satisfaction Enhancement",
        icon: dutyImg1,
        message: "",
        list: [
            "Allow providers to engage more fully with patients without distraction.",
            "Improve the overall patient experience by ensuring their concerns are thoroughly documented and addressed."
        ]
    },
    {
        title: "Customizable Support",
        icon: dutyImg1,
        message: "",
        list: [
            "Tailor services to match the specific needs and preferences of your practice.",
            "Offer flexibility in scheduling to accommodate peak hours or specific provider requirements."
        ]
    },
    {
        title: "Remote Accessibility",
        icon: dutyImg1,
        message: "",
        list: [
            "Provide scribing support regardless of your practice's location.",
            "Easily integrate with your existing systems without the need for additional office space."
        ]
    },
    {
        title: "Collaboration with the Care Team",
        icon: dutyImg1,
        message: "",
        list: [
            "Work cohesively with nurses, medical assistants, and administrative staff.",
            "Facilitate better coordination and communication within the healthcare team."
        ]
    },
    {
        title: "Continuous Learning and Improvement",
        icon: dutyImg1,
        message: "",
        list: [
            "Stay informed about the latest medical guidelines and documentation practices.",
            "Engage in ongoing training to enhance skills and adapt to new technologies."
        ]
    },
]

const howItWorks = [
    {
        title: "Submit Your Inquiry & Discovery Call",
        img: dutyImg1,
        message: "Share your needs by submitting an inquiry, and we’ll schedule a discovery call to discuss your requirements, clarify expectations, and answer any questions you have."
    },
    {
        title: "Interview Hand-Selected Candidates",
        img: dutyImg2,
        message: "We’ll provide a shortlist of qualified candidates based on your needs. You’ll interview them to ensure they’re the perfect match for your practice."
    },
    {
        title: "Start Working with Your VMH",
        img: dutyImg3,
        message: "Once you’ve chosen your candidate, they’ll start supporting your practice right away, providing tailored assistance and immediate value."
    }
]

const hippas = [
    {
        img: feature1,
        title: "Comprehensive Training for Virtual Medical Assistant",
        message: "All Virtual Medical Assistant (VMHs) receive extensive training on HIPAA regulations, including:",
        options: [
            "Proper handling of Protected Health Information (PHI).",
            "Identifying and mitigating risks of data breaches.",
            "Secure communication practices to prevent unauthorized access."
        ]
    },
    {
        img: feature2,
        title: "Secure Communication Channels",
        message: "We ensure that all communication between VMHs and your practice is conducted via HIPAA-compliant platforms, such as:",
        options: [
            "Encrypted email services.",
            "Secure file-sharing systems.",
            "HIPAA-compliant phone and video conferencing tools."
        ]
    },
    {
        img: feature3,
        title: "Access Control and Authorization",
        options: [
            "VMHs are granted access only to the information necessary to perform their duties.",
            "Role-based permissions ensure that sensitive data is restricted to authorized personnel."
        ]
    },
    {
        img: feature1,
        title: "Data Encryption",
        message: "All transmitted and stored data is encrypted to safeguard it from potential breaches or unauthorized access."
    },
    {
        img: feature4,
        title: "Regular Audits and Monitoring",
        message: "Honest Taskers conducts routine internal audits to ensure ongoing compliance with HIPAA standards and proactively address potential vulnerabilities."
    },
    {
        img: feature5,
        title: "Business Associate Agreements (BAA)",
        message: "We provide Business Associate Agreements to all dental practices, ensuring a clear understanding of responsibilities and compliance requirements."
    },
    {
        img: feature6,
        title: "Secure Work Environment for VMHs",
        options: [
            "VMHs work in private, monitored environments to prevent unauthorized viewing or sharing of PHI.",
            "Policies are in place to prevent the use of unsecured devices or networks."
        ]
    },
    {
        img: feature1,
        title: "Incident Management Protocols",
        message: "In the rare event of a security incident, Honest Taskers has a well-defined protocol to:",
        options: [
            "Immediately contain and resolve the issue.",
            "Notify your practice promptly.",
            "Take corrective actions to prevent recurrence."
        ]
    },
    {
        img: feature1,
        title: "Continuous Education",
        message: "VMAs participate in ongoing HIPAA training to stay updated on evolving regulations and best practices for maintaining data security."
    },
]
import React from "react";
import './style.scss';

export default function Features() {

    const checkmark = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAPFBMVEUAAAAlpO8jpO8ipO8ipe8jpO8io+8ipe8ipO8ipO8ipe8ipO8jo+8jpe8ipO8gn+8ko+8jo+wlpe8jpO9JohRoAAAAE3RSTlMAIO9f37/ff48/n3BQr88QgFAwCw8IQAAAANtJREFUKM9909sCgiAMBuAdGAmEVv/7v2sEoaLWrtDPAZNBa4RpFkBsCnQMdSjiXHE4HYgNMF3qUHNh3uwusN0jR8h9NSDREDfgqyzQ0xYgbSqHG50iwdpHkS7CoDWRr5DhSu3Ix9eWWmqgCXowh4qKiQw8Wt/fgplEro0+Al9Hr3Q0cugYEbtt2KdljzjaR2YsXfNgDKuldF2tlxJga/F7o4xA5H/9Pl/T7QpjW86QzvaA/3/YvU0eoyXguWuwyEMvSrWtxqzVFzXAeFzEb03tdaB2HUyKz7vr8AbCUAoWXwgLTAAAAABJRU5ErkJggg==";

    const techRequirements = [
        "USB Headset with Noise Cancellation feature Working Webcam",
        "Main and backup computer: at least 1.8 GHz processor with at least 4GB RAM",
        "Main Internet Service Speed: at least 25 Mbps wired connection",
        "Backup Internet Service Speed: at least 10 Mbps",
        "Have a backup power source ",
        "Have a dedicated workspace design for remote work. ",
        "Available to work full time without any interuption"
    ]

    const benefits = [
        "Free training",
        "Healthcare after 3 months",
        "Paid sick days",
        "Paid U.S holidays ",
        "Competitive pay",
        "Earn performance bonus",
        "Yearly pay increases",
        "Work remotely from the comfort of your home",
        "Supportive coworkers"
    ]

    const jobs = [
        "Virtual Medical Assistant",
        "Virtual Dental Assistant",
        "Virtual Medical Biller",
        "Virtual Medical Coder",
        "Virtual Medical Receptionist",
        "Virtual Dental Receptionist",
        "Virtual Medical Scribe",
        "Virtual Medical Chart Auditors",
        "Virtual MDS Coordinators",
        "Remote Patient Monitoring VA",
        "Telehealth Virtual Nurse",
        "Mental Health Virtual Medical Assistant",
        "Bilingual Medical Virtual Assistant"
    ]

    const minimumRequirments = [
        "OS: Windows 10 Home/Pro 64-bit or macOS 10.14",
        "CPU: Intel Core i3 (6th gen or later) or equivalent",
        "RAM: 6GB or more",
        "Internet: 10 Mbps (cable modem, DSL, or fiber)",
        "Backup: LTE connection"
    ]

    const recommendedRequirements = [
        "OS: Windows 10 Home/Pro 64-bit",
        "CPU: Intel Core i5 8th gen or later",
        "RAM: 8GB or more",
        "Internet: 20 Mbps (cable modem, DSL, or fiber)",
        "Backup: LTE connection"
    ]

    const periferrals = [
        "Noise-canceling Headset with microphone (Plantronics Audio 628 or equivalent)",
        "HD 720p/1080p 16:9 Webcam (A4Tech PK-910H or equivalent)"
    ]

    const workRequirements = [
        "Education/Training: Provide proof of a relevant degree or certificate (e.g., nursing, medical transcription).",
        "Valid ID: Submit a government-issued photo ID (e.g., driver's license, passport).",
        "Background Check: NBI clearance or an equivalent police report from your country.",
        "Availability: Full-time shifts for applicants from both the Philippines and Latin America.",
        "Workspace: Maintain a quiet, professional environment with neutral background lighting.",
        "Tax Registration: Register with the BIR as an independent contractor upon qualification.",
        "References: Be able to provide character and professional references."
    ]

    return (
        <div className="vafeatures-component">
            <div className="container v-r">
                <h1>Features</h1>
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 sub-col v-r">
                        <div className="item-wrapper">
                            <div className="text-container v-r">
                                <h2>Technical Requirements</h2>
                                {
                                    techRequirements.map((item, index) => (
                                        <div className="content-item" key={index}>
                                            <img src={checkmark} alt="checkmark" />
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="bg-dot small-dot" alt="bgdot"></div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 sub-col v-r">
                        <div className="item-wrapper">
                            <div className="text-container v-r">
                                <h2>Benefits</h2>
                                {
                                    benefits.map((item, index) => (
                                        <div className="content-item" key={index}>
                                            <img src={checkmark} alt="checkmark" />
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="bg-dot small-dot" alt="bgdot"></div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 sub-col v-r">
                        <div className="item-wrapper">
                            <div className="text-container v-r">
                                <h2>Job Opportunities</h2>
                                {
                                jobs.map((item, index) => (
                                        <div className="content-item" key={index}>
                                            <img src={checkmark} alt="checkmark" />
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="bg-dot small-dot" alt="bgdot"></div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 sub-col v-r">
                        <div className="item-wrapper">
                            <div className="text-container v-r">
                                <h2>Tech Requirements</h2>
                                <div className="sub-title">Minimum Requirements:</div>
                                {
                                minimumRequirments.map((item, index) => (
                                        <div className="content-item" key={index}>
                                            <img src={checkmark} alt="checkmark" />
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                                <div className="sub-title">Recommended Requirements:</div>
                                {
                                recommendedRequirements.map((item, index) => (
                                        <div className="content-item" key={index}>
                                            <img src={checkmark} alt="checkmark" />
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                                <div className="sub-title">Required Peripherals:</div>
                                {
                                periferrals.map((item, index) => (
                                        <div className="content-item" key={index}>
                                            <img src={checkmark} alt="checkmark" />
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="bg-dot small-dot" alt="bgdot"></div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 sub-col v-r">
                        <div className="item-wrapper">
                            <div className="text-container v-r">
                                <h2>Work Requirements:</h2>
                                {
                                workRequirements.map((item, index) => (
                                        <div className="content-item" key={index}>
                                            <img src={checkmark} alt="checkmark" />
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="bg-dot small-dot" alt="bgdot"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
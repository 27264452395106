import './style.scss';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";
import { ArrowLeftOne, ArrowRightOne } from "src/Constant";
import { ReviewModal } from '../ReviewModal';
import { StarComponent } from 'src/Widgets';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenReview, toggleCalendlyModal, toggleReviewModal } from 'src/Redux/Actions';
import { useState } from 'react';

const ReviewItem = ({item, onSeeMore}) => {
    return (
        <div className='review-item clamp'>
            <div className="review-header">
                <div className="avatar center">{item.initials}</div>
                <div className="reviewer-info flex-1">
                    <h3 className='name'>{item.name}</h3>
                    {/* <div className="address v-c">Fort Worth, TX • Jan 23, 2024</div> */}
                </div>
            </div>
            <div className='user-rating d-f a-c'>
                {
                    Array(5).fill(true).map((item, index) => (
                        <StarComponent key={index}/>
                    ))
                }
            </div>
            <div className='message'>{item.message}</div>
            <p className="continue-btn mb-0" onClick={onSeeMore}>Continue Reading</p>
        </div>
    )
}

export default function Reviews({
    reviews, 
    title = "Our Dental Practice Owners and Managers Love Us As Much As We Love Them"
}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const dispatch = useDispatch();
    const {
        showReviewModal,
        review
    } = useSelector(state => state.Home);

    const handleSeeMore = (item) => {
        dispatch(setOpenReview(item))
        dispatch(toggleReviewModal(true))
    }

    return (
        <div className='adwords-reviews'>
            <div className='container'>
                <div className='padding'>
                    <div className='row'>
                        <div className='col-lg-4 left-section d-f f-c j-c'>
                            <h2>{title}</h2>
                            <div className='book-call center mt-5' onClick={() => dispatch(toggleCalendlyModal(true))}>Book Discovery Call</div>
                        </div>
                        { review && <ReviewModal show={showReviewModal} onHide={() => dispatch(toggleReviewModal(false))} item={review}/>}
                        <div className='col-lg-8 right-section'>
                             <Media
                                queries={{
                                    lg: "(min-width: 1655px)",
                                    md: "(min-width: 1360px) and (max-width: 1654px)",
                                    sm: "(min-width: 1032px) and (max-width: 1359px)",
                                    normal: "(max-width: 1031px)",
                                }}
                            >
                            {(matches) => (
                                <OwlCarousel 
                                    className="owl-theme"
                                    key={999}
                                    stagePadding={
                                        matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 0
                                    }
                                    margin={
                                        matches.lg ? 20 : matches.md ? 20 : matches.sm ? 10 : 0
                                    }
                                    items={matches.lg ? 2 : matches.md ? 1 : matches.sm ? 1 : 1}
                                    navText={[ArrowLeftOne, ArrowRightOne]}
                                    nav={true}
                                    dots={false}
                                    autoplay
                                    autoplayHoverPause
                                    autoplayTimeout={2000}
                                    startPosition={currentIndex}
                                    onTranslated={(event) => {
                                        setCurrentIndex(event.item.index);
                                    }}
                                    >
                                        {
                                            reviews.map((item, index) => (
                                                <ReviewItem key={index} item={item} onSeeMore={() => handleSeeMore(item)}/>
                                            ))
                                        }
                                    </OwlCarousel>
                                )}
                            </Media>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
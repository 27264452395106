
import { takeLatest, put } from 'redux-saga/effects';

import {
    getCourses as getCoursesApi,
} from 'src/Api/Portal'
import { GET_COURSES, GET_COURSES_FAIL, GET_COURSES_SUCCESS } from '../Type';

function* getCourses() {
    try {
        const result = yield getCoursesApi();
        if (result && result.data) {
          yield put({ type: GET_COURSES_SUCCESS, data: result.data });
        } else {
          yield put({ type: GET_COURSES_FAIL, data: null });
        }
      } catch (err) {
        alert(err);
      }
}

export function* whatchGetCourses() {
    yield(takeLatest(GET_COURSES, getCourses))
}
import { useNavigate } from 'react-router-dom';
import './style.scss';
import homeImg from 'src/Assets/Images/VmrHome/HomeImg.svg';

export default function HeroSection({title}) {
    const navigate = useNavigate()

    return (
        <div className='adwords-hero'>
            <div className='container'>
                <div className='padding'>
                    <div className='row'>
                        <div className='left-section col-lg-6'>
                            <img src={homeImg} alt='Virtual Dental Assistant'/>
                        </div>
                        <div className='right-section col-lg-6'>
                            <h2>{title}</h2>
                            <div className='action-btns d-f a-c'>
                                <div className='yes action-btn center' onClick={() => navigate('/va/request-VA')}>Yes</div>
                                <div className='no action-btn center'>No</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { AdwordsFAQs, AdwordsHeroSection, AdwordsHowItWorks, AdwordsReviews, AdwordsServices, AdwordsWhyUs, BrandingSection, FooterBottom, FreeTrialSection, HIPAACompliance, Pricing, RequestDiscoveryCall } from "src/Components";
import CallToAction from "src/Components/Adwords/CallToAction";
import Showcase from "src/Components/Adwords/Showcase";
import { getPricingPlans } from "src/Constant";
import dutyImg1 from 'src/Assets/Images/VmrHome/Duty1.svg';
import dutyImg2 from 'src/Assets/Images/VmrHome/Duty2.svg';
import dutyImg3 from 'src/Assets/Images/VmrHome/Duty3.svg';
import dutyImg4 from 'src/Assets/Images/VmrHome/Duty4.svg';
import feature1 from 'src/Assets/Images/VmsHome/Feature1.svg';
import feature2 from 'src/Assets/Images/VmsHome/Feature2.svg';
import feature3 from 'src/Assets/Images/VmsHome/Feature3.svg';
import feature4 from 'src/Assets/Images/VmsHome/Feature4.svg';
import feature5 from 'src/Assets/Images/VmsHome/Feature5.svg';
import feature6 from 'src/Assets/Images/VmsHome/Feature6.svg';
import storyImg2 from 'src/Assets/Images/Story/Story2.svg';
import storyImg3 from 'src/Assets/Images/Story/Story6.svg';
import storyImg4 from 'src/Assets/Images/Home/FaqHImg.svg';
import storyImg5 from 'src/Assets/Images/Home/WhyHImg.svg';
import policy2 from 'src/Assets/Images/Policy/P2.svg';
import policy3 from 'src/Assets/Images/Policy/P3.svg';
import policy5 from 'src/Assets/Images/Policy/P5.svg';
import policy7 from 'src/Assets/Images/Policy/P7.svg';
import policy8 from 'src/Assets/Images/Policy/P8.svg';
import service1 from 'src/Assets/Images/Services/Services1.svg';
import service2 from 'src/Assets/Images/Services/Services2.svg';
import service3 from 'src/Assets/Images/Services/Services3.svg';
import service4 from 'src/Assets/Images/Services/Services4.svg';
import service5 from 'src/Assets/Images/Services/Services5.svg';
import vision1 from 'src/Assets/Images/Vision/Vision1.svg';
import vision2 from 'src/Assets/Images/Vision/Vision2.svg';


export default function VMB_AdwordsPage() {

    const heroTitle = "Do You Need An Experience Virtual Medical Biller or Coder To Support Your Practice and Save 70% On Labor Costs?";
    const howItWorksTitle = "This entire process is streamlined and typically takes <b>1–2 weeks</b>, allowing you to quickly integrate a skilled Virtual Medical Billing & Coding into your team.";
    const requestTitle = "Get More Information About Our Virtual Medical Billing & Coding Staffing Services";
    const requestSubTitle = "We pride ourselves on having a warm and approachable team. You’ll love talking to us as we guide you through finding the perfect Virtual Medical Billing & Coding for your practice!";
    return ( 
        <div className="vdr-landing">
           <AdwordsHeroSection title={heroTitle}/>
           <BrandingSection />
           <AdwordsReviews reviews={reviews} title="Our Medical Practice Owners and Managers Love Us As Much As We Love Them"/>
           <Showcase titleVA="Who Are Our Virtual Medical Billing & Coding?" titleClient="What Our Clients Have To Say About Their Medical VA"/>
           {/* <CallToAction /> */}
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Medical Billing & Coding Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <AdwordsWhyUs title={"Why Hire Our Virtual Medical Billing & Coding Services"} whyusList={whyusList}/>
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Medical Billing & Coding Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <AdwordsServices title={"Ways Our Medical Biller or Coder Can Support Your Practice"} services={services}/>
           <AdwordsHowItWorks howItWorks={howItWorks} title={howItWorksTitle}/>
           <HIPAACompliance hippas={hippas}/>
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Medical Billing & Coding Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <Pricing plans={getPricingPlans('pricing-one')}/>
           <AdwordsFAQs faqs={faqs}/>
           <RequestDiscoveryCall title={requestTitle} subTitle={requestSubTitle}/>
           <FooterBottom />
        </div>
    )
}

const reviews = [
    {
        name: "Dr. Rachel Simmons",
        initials: "RS",
        subTitle: "Family Medicine",
        message: "Our Virtual Medical Biller has been an incredible addition to our team. Billing used to be a constant headache, with claims falling through the cracks and denials piling up. Since integrating Honest Taskers into our workflow, our claims are submitted faster, denials are addressed promptly, and our revenue cycle is more predictable. The best part is how well they communicate—they’re proactive and make sure we’re always in the loop. It’s like having another in-office staff member, but without the extra overhead!"
    },
    {
        name: "Dr. Steven Clark",
        initials: "SC",
        subTitle: "Pediatrics",
        message: "I was hesitant at first to bring on a Virtual Medical Biller, but Honest Taskers completely changed my mind. Our VMB has streamlined our billing process, making it more efficient and accurate. They’ve taken on everything from claim submissions to following up on outstanding payments, which has freed up my staff to focus on patient care. I’m amazed at how quickly they learned our systems and adapted to our workflows. I can’t imagine going back to how we managed billing before!"
    },
    {
        name: "Emily Carter",
        subTitle: "Internal Medicine",
        initials: "EC",
        message: "Our practice was struggling to stay on top of billing, and it was causing delays in reimbursements and stress for the team. Honest Taskers connected us with a Virtual Medical Biller who completely turned things around. They handle everything from insurance claims to payment tracking with such efficiency that we’ve seen a significant reduction in errors and faster reimbursement times. Plus, they’re incredibly responsive and proactive, which has made the whole process smoother. We’re so grateful to have them as part of our team!"
    },
    {
        name: "Dr. Laura Bennett",
        subTitle: "Dermatology",
        initials: "LB",
        message: "I needed a certified medical coder with substantial experience, and Honest Taskers delivered exactly what I was looking for. They matched us with a Virtual Medical Coder who has over seven years of coding experience, and it’s been an absolute relief to have someone so knowledgeable on our team. From day one, they’ve handled our coding needs with precision, ensuring accuracy and compliance with the latest regulations. Their expertise has significantly reduced errors, improved our claim acceptance rates, and sped up reimbursements. It’s clear they take pride in their work, and it shows in the results. I couldn’t be happier with the support from Honest Taskers!"
    }
]

const services = [
    {
        title: "Claims Submission and Management",
        icon: dutyImg1,
        message: "",
        list: [
            "Submit clean claims to insurance companies promptly.",
            "Follow up on outstanding claims to ensure timely reimbursements."
        ]
    },
    {
        title: "Medical Coding",
        icon: dutyImg2,
        message: "",
        list: [
            "Accurately code medical procedures, diagnoses, and treatments using CPT, ICD-10, and HCPCS codes.",
            "Ensure proper coding to maximize reimbursements and reduce denials."
        ]
    },
    {
        title: "Denial Management and Appeals",
        icon: dutyImg3,
        message: "",
        list: [
            "Review and address denied claims.",
            "Prepare and submit appeals to resolve disputes and recover payments."
        ]
    },
    {
        title: "Insurance Verification and Authorization",
        icon: dutyImg4,
        message: "",
        list: [
            "Verify patient insurance coverage and benefits before appointments.",
            "Obtain prior authorizations for treatments or procedures."
        ]
    },
    {
        title: "Patient Billing and Payment Tracking",
        icon: dutyImg4,
        message: "",
        list: [
            "Generate and send invoices to patients.",
            "Follow up on unpaid balances and assist in setting up payment plans."
        ]
    },
    {
        title: "Revenue Cycle Optimization",
        icon: dutyImg4,
        message: "",
        list: [
            "Identify bottlenecks in the revenue cycle and recommend improvements.",
            "Track financial metrics to ensure consistent cash flow and financial health."
        ]
    },
    {
        title: "EHR and Billing Software Management",
        icon: dutyImg4,
        message: "",
        list: [
            "Utilize practice-specific software like Kareo, Athenahealth, or eClinicalWorks for billing and coding tasks.",
            "Update patient accounts and maintain accurate financial records."
        ]
    },
    {
        title: "Compliance and Regulatory Support",
        icon: dutyImg4,
        message: "",
        list: [
            "Ensure coding and billing practices comply with HIPAA, Medicare, and other regulations.",
            "Stay updated on changes in coding guidelines and payer policies."
        ]
    },
    {
        title: "Payment Posting and Reconciliation",
        icon: dutyImg4,
        message: "",
        list: [
            "Post insurance and patient payments to accounts accurately.",
            "Reconcile accounts to ensure proper allocation of funds."
        ]
    },
    {
        title: "Credentialing Support",
        icon: dutyImg4,
        message: "",
        list: [
            "Assist with provider credentialing and recredentialing with insurance payers.",
            "Ensure all documentation is accurate and submitted on time."
        ]
    },
    {
        title: "Reporting and Analytics",
        icon: dutyImg4,
        message: "",
        list: [
            "Generate financial reports to track key metrics like reimbursement rates and claim denial trends.",
            "Provide insights to help improve billing efficiency and practice revenue."
        ]
    },
    {
        title: "Patient Communication",
        icon: dutyImg4,
        message: "",
        list: [
            "Respond to patient inquiries regarding billing or insurance.",
            "Explain balances, charges, and payment options clearly and professionally."
        ]
    },
    {
        title: "Workflow Streamlining",
        icon: dutyImg4,
        message: "",
        list: [
            "Identify inefficiencies in billing and coding processes.",
            "Propose and implement solutions to improve workflow and reduce errors."
        ]
    },
    {
        title: "Multilingual Support",
        icon: dutyImg4,
        message: "",
        list: [
            "Provide bilingual assistance for practices serving diverse patient populations.",
            "Communicate with patients and payers in their preferred language."
        ]
    },
    {
        title: "Prioritization of Aging Claims",
        icon: dutyImg4,
        message: "",
        list: [
            "Focus on aged claims to ensure they are resolved before deadlines.",
            "Reduce revenue loss by acting quickly on older claims."
        ]
    },
    {
        title: "Audit and Quality Assurance",
        icon: dutyImg4,
        message: "",
        list: [
            "Review coding and billing practices for accuracy and compliance.",
            "Conduct internal audits to minimize errors and prevent external penalties."
        ]
    },
    {
        title: "Flexible Hours",
        icon: dutyImg4,
        message: "",
        list: [
           " Work during evenings, weekends, or peak hours to meet the practice's specific needs.",
            "Ensure round-the-clock support for practices with high patient volumes."
        ]
    },
    {
        title: "Coordination with Payers",
        icon: dutyImg4,
        message: "",
        list: [
            "Liaise with insurance companies to resolve discrepancies and obtain clarifications.",
            "Negotiate and resolve underpaid claims effectively."
        ]
    },
    {
        title: "Backup Support",
        icon: dutyImg4,
        message: "",
        list: [
            "Provide consistent coverage during staff absences or busy periods.",
            "Reduce the risk of workflow disruptions by acting as a reliable backup."
        ]
    },
    {
        title: "Assistance with Transitioning Software",
        icon: dutyImg4,
        message: "",
        list: [
            "Support practices during software transitions by handling billing and coding tasks.",
            "Train in-house staff on new systems to ensure smooth adoption."
        ]
    },
    {
        title: "Improved Patient Satisfaction",
        icon: dutyImg4,
        message: "",
        list: [
            "Resolve billing concerns efficiently, ensuring a positive patient experience.",
            "Maintain professional communication that builds trust and confidence in the practice."
        ]
    },
    {
        title: "Expertise in Multiple Specialties",
        icon: dutyImg4,
        message: "",
        list: [
            "Offer tailored coding and billing services for specialties like cardiology, orthopedics, pain management, pediatrics, and more."
        ]
    },
    {
        title: "Reduction in Administrative Burden",
        icon: dutyImg4,
        message: "",
        list: [
           "Take over time-consuming billing and coding tasks, allowing in-house staff to focus on patient care and other responsibilities."
        ]
    },
    {
        title: "Ongoing Education and Updates",
        icon: dutyImg4,
        message: "",
        list: [
            "Stay current with coding and billing guidelines, ensuring compliance and accuracy.",
            "Adapt to changes in payer policies and industry standards."
        ]
    },
    {
        title: "Cost Savings",
        icon: dutyImg4,
        message: "",
        list: [
            "Provide high-quality billing and coding support at a lower cost than hiring full-time in-office staff.",
            "Reduce overhead expenses like office space, benefits, and training."
        ]
    },
]

const whyusList = [
    {
        title: "Significant Cost Savings",
        icon: service1,
        message: "Honest Taskers helps dental practices save 65% or more on labor costs by hiring Virtual Medical Assistant (VMAs). Without the added expenses of taxes, benefits, and paid time off, practices can significantly reduce overhead while still receiving high-quality, reliable support.",
        list: []
    },
    {
        title: "Access to Expert Talent",
        icon: service2,
        message: "Honest Taskers connects you with highly skilled and certified professionals experienced in:",
        list: [
            "Medical coding (CPT, ICD-10, and HCPCS)",
            "Claims submission and denial management",
            "Revenue cycle optimization",
            "Insurance verification and prior authorizations",
            "Billing software proficiency, including Kareo, Athenahealth, eClinicalWorks, AdvancedMD, and more"
        ]
    },
    {
        title: "Certified and Experienced Coders",
        icon: service3,
        message: "Our Virtual Medical Coders are certified professionals (CPC, CCS, or equivalent) with extensive experience, including matching practices with coders who have 7+ years of expertise in their field.",
        list: []
    },
    {
        title: "Reduced Turnover and Reliable Support",
        icon: service4,
        message: "High turnover is a common issue in medical billing departments. Honest Taskers provides long-term, reliable staff, eliminating the disruption of frequent retraining.",
        list: []
    },
    {
        title: "HIPAA-Compliant and Secure",
        icon: service5,
        message: "All Honest Taskers Virtual Medical Billers and Coders are trained in HIPAA compliance, ensuring the confidentiality and security of patient data at all times.",
        list: []
    },
    {
        title: "Improved Revenue Cycle Management",
        icon: vision1,
        message: "With accurate coding, efficient claims submission, and effective denial management, Honest Taskers helps practices accelerate reimbursements and reduce errors that lead to delays.",
        list: []
    },
    {
        title: "Customized Solutions for Practice Needs",
        icon: vision2,
        message: "We offer tailored training to ensure your Virtual Medical Biller or Coder is prepared to meet your specific workflow and practice requirements, optimizing results.",
        list: []
    },
    {
        title: "Flexibility and Scalability",
        icon: storyImg4,
        message: "Our Virtual Medical Billers and Coders work the hours you need, including evenings or weekends, and require no physical office space. As your practice grows, you can easily scale your virtual billing team.",
        list: []
    },
    {
        title: "Enhanced Efficiency for Your Team",
        icon: storyImg3,
        message: "By delegating billing and coding tasks to Honest Taskers, your in-house team can focus on delivering quality patient care without being bogged down by administrative tasks.",
        list: []
    },
    {
        title: "Two-Week Trial to Ensure the Perfect Fit",
        icon: policy2,
        message: "We offer a two-week trial, allowing you to assess the performance of your Virtual Medical Biller or Coder and confirm they meet your practice’s unique needs.",
        list: []
    },
    {
        title: "No Long-Term Commitment",
        icon: policy5,
        message: "With no long-term contracts, Honest Taskers provides flexibility to adapt to your practice’s evolving needs.",
        list: []
    },
    {
        title: "Proven Results",
        icon: policy8,
        message: "Medical practices using Honest Taskers report reduced costs, improved accuracy, faster reimbursements, and measurable growth in revenue cycle performance.",
        list: []
    },
    {
        title: "Bilingual Support for Diverse Patient Bases",
        icon: policy7,
        message: "If your practice serves multilingual communities, our bilingual Virtual Medical Billers and Coders can enhance communication with payers and patients, ensuring smooth operations.",
        list: []
    },
    {
        title: "Core Values-Driven Hiring",
        icon: policy8,
        message: "We hire not just for skills but also for alignment with core values that positively impact your team’s culture and operational success.",
        list: []
    },
]

const faqs = [
    {
        question: "What tasks can the Virtual Medical Billers and Coders handle for my practice?",
        answer: "Our Virtual Medical Billers and Coders can manage a wide range of tasks, including:",
        options: [
            "Insurance claims submission and follow-ups",
            "Medical coding using CPT, ICD-10, and HCPCS standards",
            "Denial management and appeals",
            "Insurance verification and prior authorizations",
            "Payment posting and reconciliation",
            "Patient billing and payment tracking",
            "Revenue cycle optimization and reporting",
            "Compliance and audit support"
        ]
    },
    {
        question: "Are your Virtual Medical Billers and Coders certified?",
        answer: "Yes, all our coders hold certifications such as CPC, CCS, or equivalent. They are highly skilled and experienced in medical billing and coding, ensuring accuracy and compliance."
    },
    {
        question: "How much experience do your Virtual Medical Billers and Coders have?",
        answer: "Our Virtual Medical Billers and Coders typically have 5+ years of experience and specialize in various medical fields, including pain management, cardiology, dermatology, and pediatrics."
    },
    {
        question: "How do you ensure accuracy in coding and billing?",
        message: "Our Virtual Medical Billers and Coders follow strict protocols, conduct regular audits, and stay updated with the latest coding guidelines. This ensures compliance and reduces claim denials."
    },
    {
        question: "What software platforms are your billers and coders proficient in?",
        answer: "Our team is skilled in using popular billing and coding software, including:",
        options: [
            "Athenahealth",
            "Kareo",
            "eClinicalWorks",
            "Epic",
            "AdvancedMD",
            "ETC",
            "Tebra",
            "If your practice uses other tools, we can provide additional training."
        ]
    },
    {
        question: "Are your Virtual Medical Billers and Coders HIPAA-compliant?",
        message: "Yes, all our staff are trained in HIPAA compliance. We use secure, encrypted communication tools and implement access controls to safeguard patient data."
    },
    {
        question: "How does the onboarding process work?",
        answer: "Onboarding typically takes 1–2 weeks. We provide initial training on general billing and coding tasks and tailor training for your specific workflows and software.",
    },
    {
        question: "Can they work during my practice’s hours?",
        answer: "Yes, our Virtual Medical Billers and Coders offer flexible schedules to align with your practice’s needs, including evenings, weekends, and peak hours."
    },
    {
        question: "What happens if my Virtual Medical Biller or Coder is unavailable?",
        answer: "We ensure continuity with backup staff or quick replacements in case of emergencies or unexpected absences."
    },
    {
        question: "How is their performance monitored?",
        answer: "Performance is tracked through regular productivity reports, quality checks, and feedback from your practice. Any concerns are promptly addressed."
    },
    {
        question: "Can they handle denial management and appeals?",
        answer: "Absolutely. Our Virtual Medical Billers and Coders review denied claims, identify the causes, and prepare effective appeals to recover reimbursements."
    },
    {
        question: "What is the cost of hiring a Virtual Medical Biller or Coder?",
        answer: "Yes, our VMAs specialize in insurance-related tasks, including verifying patient benefits, submitting claims, and following up on denials or pending claims."
    },
    {
        question: "Do you offer a trial period?",
        answer: "Yes, we provide a two-week trial so you can assess the fit of your Virtual Medical Biller or Coder before committing."
    },
    {
        question: "Can they help with improving our revenue cycle?",
        answer: "Yes, our Virtual Medical Billers and Coders are trained to identify inefficiencies in your revenue cycle, recommend improvements, and track metrics to optimize cash flow."
    },
    {
        question: "How do they communicate with my team?",
        answer: "They use secure communication tools such as Slack, Microsoft Teams, Zoom, and HIPAA-compliant email to ensure seamless collaboration with your team.",
    },
    {
        question: "Are there any long-term contracts?",
        answer: "No, we offer flexible arrangements with no long-term commitments, allowing you to scale your team as needed.",
    },
    {
        question: "Do they work for specific medical specialties?",
        answer: "Yes, our Virtual Medical Billers and Coders have experience in various specialties, including:",
        options: [
            "Pain management",
            "Cardiology",
            "Pediatrics",
            "Dermatology",
            "Internal medicine"
        ]
    },
    {
        question: "How do you handle training for specific workflows or requirements?",
        answer: "We customize training based on your practice’s unique needs, ensuring our Virtual Medical Billers and Coders integrate seamlessly into your workflows."
    },
    {
        question: "Can they handle multilingual communication?",
        answer: "Yes, many of our Virtual Medical Billers and Coders are bilingual, with fluency in English and other languages such as Spanish, to support diverse patient populations."
    },
    {
        question: "Where are your Virtual Medical Billers and Coders located?",
        answer: "Our Virtual Medical Billers and Coders are based in the Philippines and Latin America, offering cost-effective, high-quality support.",
    },
]

const howItWorks = [
    {
        title: "Submit Your Inquiry & Discovery Call",
        img: dutyImg1,
        message: "Share your needs by submitting an inquiry, and we’ll schedule a discovery call to discuss your requirements, clarify expectations, and answer any questions you have."
    },
    {
        title: "Interview Hand-Selected Candidates",
        img: dutyImg2,
        message: "We’ll provide a shortlist of qualified candidates based on your needs. You’ll interview them to ensure they’re the perfect match for your practice."
    },
    {
        title: "Start Working with Your VMS",
        img: dutyImg3,
        message: "Once you’ve chosen your candidate, they’ll start supporting your practice right away, providing tailored assistance and immediate value."
    }
]

const hippas = [
    {
        img: feature1,
        title: "Comprehensive Training for Virtual Medical Assistant",
        message: "All Virtual Medical Assistant (VMSs) receive extensive training on HIPAA regulations, including:",
        options: [
            "Proper handling of Protected Health Information (PHI).",
            "Identifying and mitigating risks of data breaches.",
            "Secure communication practices to prevent unauthorized access."
        ]
    },
    {
        img: feature2,
        title: "Secure Communication Channels",
        message: "We ensure that all communication between VMSs and your practice is conducted via HIPAA-compliant platforms, such as:",
        options: [
            "Encrypted email services.",
            "Secure file-sharing systems.",
            "HIPAA-compliant phone and video conferencing tools."
        ]
    },
    {
        img: feature3,
        title: "Access Control and Authorization",
        options: [
            "VMSs are granted access only to the information necessary to perform their duties.",
            "Role-based permissions ensure that sensitive data is restricted to authorized personnel."
        ]
    },
    {
        img: feature1,
        title: "Data Encryption",
        message: "All transmitted and stored data is encrypted to safeguard it from potential breaches or unauthorized access."
    },
    {
        img: feature4,
        title: "Regular Audits and Monitoring",
        message: "Honest Taskers conducts routine internal audits to ensure ongoing compliance with HIPAA standards and proactively address potential vulnerabilities."
    },
    {
        img: feature5,
        title: "Business Associate Agreements (BAA)",
        message: "We provide Business Associate Agreements to all dental practices, ensuring a clear understanding of responsibilities and compliance requirements."
    },
    {
        img: feature6,
        title: "Secure Work Environment for VMSs",
        options: [
            "VMSs work in private, monitored environments to prevent unauthorized viewing or sharing of PHI.",
            "Policies are in place to prevent the use of unsecured devices or networks."
        ]
    },
    {
        img: feature1,
        title: "Incident Management Protocols",
        message: "In the rare event of a security incident, Honest Taskers has a well-defined protocol to:",
        options: [
            "Immediately contain and resolve the issue.",
            "Notify your practice promptly.",
            "Take corrective actions to prevent recurrence."
        ]
    },
    {
        img: feature1,
        title: "Continuous Education",
        message: "VMAs participate in ongoing HIPAA training to stay updated on evolving regulations and best practices for maintaining data security."
    },
]
import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { TextEditor } from "src/Components/Common";
import { toggleCertificationModalAction } from "src/Redux/Actions";

export const CertificationItem = () => {
    return (
        <div className="cert-item q-item">
            <div className="item-header">
                <div className="info">
                    <div className="title">Certification Title</div>
                    <div className="company">Organization</div>
                </div>
                <div className="action-btns">
                    <div className="item-btn center-item orange">View Certification</div>
                    <div className="item-btn center-item">Edit</div>
                    {/* <div className="item-btn danger center-item">Delete</div> */}
                </div>
            </div>
            <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        </div>
    )
}

export const CertificationComponent = () => {
    const dispatch = useDispatch()

    return (
        <div className="item-list">
            <div className="sec-title">List all your certifications and training</div>
            <div className="btn-row">
                <div className="add-btn" onClick={() => dispatch(toggleCertificationModalAction(true))}><span className="fa fa-plus me-2"></span>Add Certification</div>
            </div>
            {
                Array(2).fill(true).map((item, index) => (
                    <CertificationItem key={index}/>
                ))
            }
        </div>
    )
}

export const AddCertificationModal = ({show, onHide}) => {
    const editorRef = useRef(null);
    const submit = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    }
    return (
        <Modal className="cert-modal modal-lg" show={show} onHide={onHide}>
            <Modal.Header>
                <div className="modal-title">Add Certification</div>
                <div className="close" onClick={onHide}>Close</div>
            </Modal.Header>
            <Modal.Body>
                <div className="form-wrapper row">
                    <div className="form-group group-2 col-lg-6">
                        <label>Certification Title</label>
                        <input type="text" className="form-control"/>
                    </div>
                    <div className="form-group group-2 col-lg-6">
                        <label>Organization</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="form-group group-2 col-lg-12">
                        <label>Certification link</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="form-group group-2">
                        <label>Description</label>
                        <TextEditor editorRef={editorRef}/>
                    </div>
                    <div className="modal-btns">
                        <div className="submit-btn center-item" onClick={() => submit()}>Save</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
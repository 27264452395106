import { TOGGLE_ATTENTION_TEST_MODAL, TOGGLE_CERTIFICIATION_MODAL, TOGGLE_EXPERIENCE_MODAL, TOGGLE_SKILL_FORM_MODAL } from "../Type"

export const toggleExperienceModalAction = (show) => {
    return {
        type: TOGGLE_EXPERIENCE_MODAL,
        data: { show: show }
    }
}

export const toggleCertificationModalAction = (show) => {
    return {
        type: TOGGLE_CERTIFICIATION_MODAL,
        data: { show: show }
    }
}
export const toggleSkillModalAction = (show) => {
    return {
        type: TOGGLE_SKILL_FORM_MODAL,
        data: { show: show }
    }
}
export const toggleAttentionTestModalAction = (show) => {
    return {
        type: TOGGLE_ATTENTION_TEST_MODAL,
        data: { show: show }
    }
}
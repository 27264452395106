import React, { useRef, useState } from "react";
import { InputComponent } from "src/Components/Common";
import SubmitArea from "../SubmitArea";

export default function VideoInterview() {
    const iframeRef = useRef(null)
    const [video, setVideo] = useState(null)

    return (
        <div className="video-interview">
            <h1 className="text-center">Video Interview</h1>
            <div className="video-wrapper mt-5">
                <iframe src={"https://muse.ai/embed/y8zH87z".replace('vc', 'embed')} 
                    ref={iframeRef}
                    style={{ width:"100%", height:"500px" }}
                    frameBorder="0" 
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen></iframe>
            </div>
            <div className="mt-5 mb-2" style={{fontSize:"20px",fontWeight:"bold"}}>Enter your video link</div>
            <InputComponent value={video} onChange={(value) => setVideo(value)}/>
            <SubmitArea onContinue={() => {}} onBack={() => {}}/>
        </div>
    )
}
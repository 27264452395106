import React from "react";
import './style.scss';
import Countdown from "react-countdown";
import { AttentionTestModal } from "./AttentionTestModal";
import { useDispatch, useSelector } from "react-redux";
import { toggleAttentionTestModalAction } from "src/Redux/Actions";
import SubmitArea from "../SubmitArea";

export default function AttentionToDetails() {
    const dispatch = useDispatch()
    const {
        showAttentionTest
    } = useSelector(state => state.Signup)

    const StartBtn = () => {
        return (
            <div className="btn-wrapper">
                <div className="start-test-btn" onClick={() => dispatch(toggleAttentionTestModalAction(true))}>Start Your Attention to details Test</div>
            </div>
        )
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          return <StartBtn />
        } else {
          return <span className="count-down">{hours}:{minutes}:{seconds}</span>;
        }
    };

    const ResultsScreen = () => {
        return (
            <div className="results">
                <div className="passed">
                    <h2>Congratulations!</h2>
                    <div className="msg">You’ve successfully completed the test.</div>
                    <small>Score:</small>
                    <div className="score green">70%</div>
                </div>
                {/* <div className="failed">
                    <h2>Better Luck Next Time!</h2>
                    <div className="msg">Sorry, you failed the test. Try again later.</div>
                    <small className="retry">Score:</small>
                    <div className="score red">30%</div>
                    <div className="waiting">
                        <small className="me-2">Retry in:</small>
                        <Countdown date={Date.now() + 5000000} renderer={renderer} />
                    </div>
                </div> */}
            </div>
        )
    }

    return (
        <div className="attention-to-details">
            {/* <h1 className="text-center">Attention to details test</h1> */}
            <div className="form-cotent mt-5 d-f j-c">
                <StartBtn />
            </div>
            <AttentionTestModal show={showAttentionTest} onHide={() => dispatch(toggleAttentionTestModalAction(false))}/>
            <SubmitArea onContinue={() => {}} onBack={() => {}}/>
        </div>
    )
}
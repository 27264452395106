

import { useState } from 'react';
import './style.scss';

export default function AdwordsWhyUs({title, whyusList}) {
    const [count, setCount] = useState(5);
    const [itemIndex, setItemIndex] = useState(0)

    const CategoryItem = ({index, item, className, onClick}) => {
        return (
            <div className={`item-wrapper w-100 ${className}`}>
                <div className={`item ${className}`} onClick={onClick}>
                    <div className='num-wrapper center'>{(index+1) < 10 ? '0'+(index+1) : index+1}</div>
                    <div className='flex-1'>{item.title}</div>
                    <div className='fa fa-angle-down icon'></div>
                </div>
                <div className='item-details'>
                    <div className='img-wrapper d-f j-c'>
                        <img src={item.icon} alt='duty-img' style={{maxWidth:"100%"}}/>
                    </div>
                    <p>{whyusList[index].message}</p>
                    {
                        whyusList[index].list.length > 0 && (
                            <ul>
                                {
                                    whyusList[index].list.map((listItem, listIndex) => (
                                        <li key={`li-${listIndex}`}>{listItem}</li>
                                    ))
                                }
                            </ul>  
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        <div className='adwords-whyus'>
            <div className='container'>
                <div className='padding'>
                    <h2 className='pb-5'>{title}</h2>
                    <div className='row mt-5'>
                        <div className='col-lg-6 left-section'>
                            {
                                whyusList.slice(0, count).map((item, index) => (
                                    <CategoryItem 
                                        key={index} 
                                        index={index} 
                                        item={item} 
                                        className={`${index === itemIndex ? 'active open': ''}`}
                                        onClick={() => setItemIndex(index)}/>
                                ))
                            }
                            <div className='see-more' onClick={() => setCount(count === whyusList.length ? 5: whyusList.length)}>{count === whyusList.length ? 'See Less' : 'See More' }</div>
                        </div>
                        <div className='col-lg-6 right-section'>
                            <div className='img-wrapper'>
                                <img src={whyusList[itemIndex].icon} alt='duty-img'/>
                            </div>
                            <div className='item-details'>
                                <p>{whyusList[itemIndex].message}</p>
                                {
                                    whyusList[itemIndex].list.length > 0 && (
                                        <ul>
                                            {
                                                whyusList[itemIndex].list.map((item, index) => (
                                                    <li key={`li-${index}`}>{item}</li>
                                                ))
                                            }
                                        </ul>  
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
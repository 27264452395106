import React, { useRef, useState } from "react";
import { SelectComponent } from "src/Components/Common";

export const ToolsComponent = () => {
    const [tools, setTools] = useState(null);

    const options = [
        { value: 'google', label: 'Google' },
        { value: 'clickup', label: 'Clickup' },
        { value: 'trello', label: 'Trello' },
    ];

    return (
        <div className="item-list">
            <div className="sec-title">Select the tools you have experience using</div>
            {/* <div className="btn-row">
                <div className="add-btn" ><span className="fa fa-plus me-2"></span>Add Skill</div>
            </div> */}
            <div className="form-group">
                <SelectComponent value={tools} options={options} onChange={(value) => setTools(value)} multiple={true} searchable={true}/>
            </div>
            {/* <div className="modal-btns mt-5">
                <div className="submit-btn center-item">Save</div>
            </div> */}
        </div>
    )
}
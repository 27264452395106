import { FaqAccordion } from 'src/Components/Home';
import './style.scss';
import { useState } from 'react';


export default function AdwordsFAQs({faqs}) {
    const [count, setCount] = useState(8);
    
    return (
        <div className='adwords-faqs'>
            <div className='container'>
                <h2 className='pb-5'>Frequently Asked Questions</h2>
                <div className="row mt-5">
                    {
                        faqs.slice(0, count).map((item, index) => (
                            <div className="col-lg-6" key={index}>
                                <FaqAccordion item={item}/>
                            </div>
                        ))
                    }
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <div className="btn btn-outline-primary" onClick={() => setCount(count === 8 ? faqs.length : 8)}>{count === 8 ? 'See More': 'See Less'}</div>
                </div>
            </div>
        </div>
    )
}


import React from "react";
import './style.scss';
import storyImg from 'src/Assets/Images/Home/OurStory.svg';
import { useDispatch } from "react-redux";
import { toggleCalendlyModal } from "src/Redux/Actions";

export default function FreeTrialSection({title = "Try Us Risk-Free: 14-Day Trial Offer", message, callToAction = false}) {
    const dispatch = useDispatch();

    return (
        <div className="trial">
            <div className="container">
                <div className="row content">
                    <div className="col-lg-6 left-content">
                        <div className="img-wrapper">
                            <img src={storyImg}/>
                        </div>
                    </div>
                    <div className="col-lg-6 right-content">
                        <h2>{title}</h2>
                        {/* <div className="title">THE GENESIS:</div> */}
                        { message && <p>{message}</p> }
                        <div className='book-call center mt-5' onClick={() => dispatch(toggleCalendlyModal(true))}>Book Discovery Call</div>
                        {/* <div className="btn btn-outline-primary mt-3" onClick={() => navigate('/story')}>READ MORE</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
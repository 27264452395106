import React from "react";
import './style.scss';
import { AttentionToDetails, DigitalProfile, HeadShotComponent, HIPAAComponent, LiveClientMatching, PersonalInfoComponent, QualificationComponent, RemoteWorkReadiness, VideoInterview } from "src/Components";
import { useSelector } from "react-redux";

export default function VACompleteProfile() {

    const signupTabs = ["Personal Information", "Qualification", "HIPAA", "Attention", "Video Interview", "Professional Headshot", "Remote Work", "Interview", "Orientation", "Schedule Client Matching", "Digital Profile"]

    const {
        profileStage
    } = useSelector(state => state.Signup)

    function ProfileFormSection() {
        switch(profileStage) {
            case 1: 
                return (<PersonalInfoComponent />)
            case 2:
                return (<QualificationComponent />)
            case 3:
                return (<HIPAAComponent />)
            case 4:
                return (<AttentionToDetails />)
            case 5:
                return (<VideoInterview />)
            case 6:
                return (<HeadShotComponent />)
            case 7:
                return (<RemoteWorkReadiness />)
            case 10:
                return (<LiveClientMatching />)
            case 11:
                return (<DigitalProfile />)
            default: 
                return (<PersonalInfoComponent />)
        }
    }
    
    return (
        <div className="signup-page">
            <div className="progress-explain">
                <div style={{fill:"white",cursor:"pointer",top:"32px",left:"31px",position:"absolute"}}>
                    {/* <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="inherit" class="injected-svg" data-src="/static/media/left-arrow.8dcc3739fa2112979d30a38736f74358.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path d="M6.4 0.599976L7.8 1.99998L3.8 5.99998H19V7.99998H3.8L7.1 11.3L7.8 12L6.4 13.4L0 6.99998L6.4 0.599976Z" fill="inherit"></path>
                        </svg>
                    </div> */}
                </div>
                <div className="bg-dot custom-bg"></div>
                <div className="explain-content">
                    <div id="section-explain-1">
                        <h1>Personal Information: </h1>
                        <p>Accurately enter your personal information here before progressing to the next step of the application</p>
                    </div>
                </div>
            </div>
            <div className="work-area">
                <div className="work-header show-web-flex">
                    <div className="container center-item">
                        <div className="work-container">
                            <div className="header-row">
                                {
                                    signupTabs.map((item, index) => (
                                        <div className={`col-name ${index+1 === profileStage ? 'active' : ''} `}>{item}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="work-section">
                    <div className="container">
                        <div className="work-content work-container" id="section-1">
                            <ProfileFormSection />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
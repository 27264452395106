import React, { useState } from "react";
import './style.scss';
import { Modal } from "react-bootstrap";

export const AttentionTestModal = ({show, onHide}) => {
    const [selectedOption, setSelectedOption] = useState(0)

    return (
        <Modal className="attention-test-modal modal-lg" show={show} onHide={onHide} fullscreen>
            <Modal.Header>
                <div className="modal-title">Attention To Details Test</div>
                <div className="close" onClick={onHide}><span className="fa fa-close"></span></div>
            </Modal.Header>
            <Modal.Body>
                <div className="test-header card">
                    <div className="question-count">Questions 1 of 20</div>
                    <div className="boxes">
                        { 
                            Array(20).fill(true).map((item, index) => (
                                <div className={`box ${index == 0 ? 'active' : ''}`}></div>
                            ))
                        }
                    </div>
                </div>
                <div className="row mt-5 test-content">
                    <div className="col-lg-6 left-section">
                        <div className="card question-card">The invitation message you provided is clear in intent but could be refined for better flow, clarity, and formality. Here's a rephrased version with proper expressions:</div>
                    </div>
                    <div className="col-lg-6 right-section">
                        <div className="card answers-card">
                            <div className="options">
                                { 
                                    Array(4).fill(true).map((item, index) => (
                                        <div className={`answer-option ${index == selectedOption ? 'selected' : ''} `} onClick={() => setSelectedOption(index)}>
                                            <div className="circle center"><div className="inner"></div></div>
                                            <div>Apple</div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="test-action-btns">
                                <div className="action-btn center back ">BACK</div>
                                <div className="action-btn center text-btn">
                                    1 <span className="ms-1"> of 20</span>
                                </div>
                                <div className="action-btn center next">NEXT</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SelectComponent, TextEditor } from "src/Components/Common";
import { toggleSkillModalAction } from "src/Redux/Actions";

const options = [
    { value: 'medical', label: 'Virtual Medical Assistant' },
    { value: 'virtual', label: 'Virtual Dental Assistant' },
];

export const SkillItem = () => {
    return (
        <div className="experience-item q-item">
            <div className="item-header">
                <div className="info">
                    <div className="title">Position</div>
                    <div className="company">skill</div>
                </div>
                <div className="action-btns">
                    <div className="item-btn center-item">Edit</div>
                    {/* <div className="item-btn danger center-item">Delete</div> */}
                </div>
            </div>
            <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        </div>
    )
}

export const SkillsComponent = () => {
    const dispatch = useDispatch()

    return (
        <div className="item-list">
            <div className="sec-title">Capture your skills</div>
            <div className="btn-row">
                <div className="add-btn" onClick={() => dispatch(toggleSkillModalAction(true))}><span className="fa fa-plus me-2"></span>Add Skill</div>
            </div>
            {
                Array(2).fill(true).map((item, index) => (
                    <SkillItem key={index}/>
                ))
            }
        </div>
    )
}

export const AddSkillModal = ({show, onHide}) => {
    const [position, setPosition] = useState(null)
    const editorRef = useRef(null);
    const submit = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    }
    return (
        <Modal className="skill-modal modal-lg" show={show} onHide={onHide}>
            <Modal.Header>
                <div className="modal-title">Add Skills</div>
                <div className="close" onClick={onHide}>Close</div>
            </Modal.Header>
            <Modal.Body>
                <div className="form-wrapper row">
                    <div className="form-group group-2 col-lg-12">
                        <label>Select Position</label>
                        <SelectComponent value={position} options={options} onChange={(value) => setPosition(value)}/>
                    </div>
                    <div className="form-group group-2 col-lg-12">
                        <label>Select Skill</label>
                        <SelectComponent value={position} options={options} onChange={(value) => setPosition(value)}/>
                    </div>
                    <div className="form-group group-2">
                        <label>Description</label>
                        <TextEditor editorRef={editorRef}/>
                    </div>
                    <div className="modal-btns">
                        <div className="submit-btn center-item" onClick={() => submit()}>Save</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toggleExperienceModalAction } from "src/Redux/Actions";
import { TextEditor } from "src/Components/Common";

export const ExperienceItem = () => {
    return (
        <div className="experience-item q-item">
            <div className="item-header">
                <div className="info">
                    <div className="title">Fullstack dev</div>
                    <div className="company">Google</div>
                </div>
                <div className="action-btns">
                    <div className="item-btn center-item">Edit</div>
                    {/* <div className="item-btn danger center-item">Delete</div> */}
                </div>
            </div>
            <div className="dates">
                <div className="item"><span className="label">Start:</span>12-09-2024</div>
                <div className="item"><span className="label">End:</span>12-09-2024</div>
            </div>
            <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        </div>
    )
}

export const ExperienceComponent = () => {
    const dispatch = useDispatch()

    return (
        <div className="item-list">
            <div className="sec-title">Capture your work experience from previous employers one at a time</div>
            <div className="btn-row">
                <div className="add-btn" onClick={() => dispatch(toggleExperienceModalAction(true))}><span className="fa fa-plus me-2"></span>Add Experience</div>
            </div>
            {
                Array(2).fill(true).map((item, index) => (
                    <ExperienceItem key={index}/>
                ))
            }
        </div>
    )
}

export const AddExperienceModal = ({show, onHide}) => {
    const editorRef = useRef(null);
    const submit = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    }
    return (
        <Modal className="experience-modal modal-lg" show={show} onHide={onHide}>
            <Modal.Header>
                <div className="modal-title">Add Experience</div>
                <div className="close" onClick={onHide}>Close</div>
            </Modal.Header>
            <Modal.Body>
                <div className="form-wrapper row">
                    <div className="form-group group-2 col-lg-6">
                        <label>Title</label>
                        <input type="text" className="form-control"/>
                    </div>
                    <div className="form-group group-2 col-lg-6">
                        <label>Organization</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="form-group col-lg-6 group-2">
                        <label>Start Date</label>
                        <input type="date" className="form-control"/>
                    </div>
                    <div className="form-group col-lg-6 group-2">
                        <label>End Date <small style={{color:"gray"}}>(Do not fill if you currently work here)</small></label>
                        <input type="date" className="form-control"/>
                    </div>
                    <div className="form-group group-2">
                        <label>Description</label>
                        <TextEditor editorRef={editorRef}/>
                    </div>
                    <div className="modal-btns">
                        <div className="submit-btn center-item" onClick={() => submit()}>Save</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

import './style.scss';
import Partner1 from 'src/Assets/Images/Home/Partner1.png';
import Partner2 from 'src/Assets/Images/Home/Partner2.png';
import Partner3 from 'src/Assets/Images/Home/Partner3.png';
import Partner4 from 'src/Assets/Images/Home/Partner4.png';
import Partner5 from 'src/Assets/Images/Home/Partner5.png';
import Partner6 from 'src/Assets/Images/Home/Partner6.png';

export default function BrandingSection() {

    const partners = [Partner1, Partner2, Partner3, Partner4, Partner5, Partner6];

    return (
        <div className='branding-section'>
            <div className='container'>
                <div className='padding'> 
                    {
                        partners.map((item, index) => (
                            <div className='item' key={index}>
                                <img src={item} alt={`partner`}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader'
import { EventEmitter } from './Utils/Events';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LandingRoutes from './Routes/LandingRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CalendlyPopUp, MobileSideMenu, PortalMobileSideMenu } from './Components';
import { getBlogsAction, getTopicsAction, toggleCalendlyModal } from './Redux/Actions';
import { AdwordsRoutes, AuthRoutes } from './Routes';
import MobileActionButton from './Components/MobileActionButton';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const override = {
  position: 'absolute',
  top: '50%',
  left: '50%',
};

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    sideMenuOpen,
    isCalendlyOpen
  } = useSelector(state => state.Home)

  const {
    portalSideMenuOpen
  } = useSelector(state => state.Portal)

  EventEmitter.subscribe('isLoading', (event) => setLoading(event));

  useEffect(() => {
    dispatch(getBlogsAction())
    dispatch(getTopicsAction())
  }, [])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <CalendlyPopUp isOpen={isCalendlyOpen} onClose={() => dispatch(toggleCalendlyModal(false))}/>
      <div className='App'>
        <div className='content' id='main'>
          <Routes>
              <Route exact path='/*' element={<LandingRoutes />}/>
              <Route exact path='/portal*' element={<AuthRoutes />} />
              <Route exact path='/va*' element={<AdwordsRoutes />} />
          </Routes>
        </div>
        <div className={loading ? "overlay-loader" : "d-none"}>
          <BeatLoader
            cssOverride={override}
            size={30}
            color={"#1BD0A5"}
            loading={loading}
          />
        </div>
        <ToastContainer />
        <MobileSideMenu show={sideMenuOpen}/>
        <PortalMobileSideMenu show={portalSideMenuOpen}/>
        <NotificationContainer />
      </div>
    </BrowserRouter>
  );
}

export default App;

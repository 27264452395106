import { combineReducers } from 'redux';

import Home from './Home'
import Contact from './Contact';
import Profile from './Profile';
import Portal from './Portal';
import Signup from './Signup';

export default combineReducers({
    Home,
    Contact,
    Profile,
    Portal,
    Signup
})
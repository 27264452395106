import './style.scss';

export default function AdwordsHowItWorks({title, howItWorks}) {

    const HowItem = ({item}) => {
        return (
            <div className='how-item'>
                <div className=''>
                    <img src={item.img} alt='image'/>
                </div>
                <div className='title'>{item.title}</div>
                <p className='message'>{item.message}</p>
            </div>
        )
    }

    return (
        <div className='how-it-works'>
            <div className='container'>
                <div className='padding'>
                    <h2>How It Works</h2>
                    <p className='text-center pb-5' dangerouslySetInnerHTML={{ __html: title }}></p>
                    <div className='row mt-5'>
                        {
                            howItWorks.map((item, index) => (
                                <div className='col-lg-4' key={index}>
                                    <HowItem item={item}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState } from "react";
import { InputComponent } from "src/Components/Common";
import SubmitArea from "../SubmitArea";

export default function HIPAA() {
    const [url, setUrl] = useState(null)

    return (
        <div className="hipaa-component">
            <h1>HOW TO COMPLETE HIPAA CERTIFICATION TRAINING</h1>
            <div className="form-content mt-5">
                <div className="">If you haven't completed your HIPAA training and certification yet, please follow these steps below. </div>
                <ul className="list-style-none mt-3">
                    <li>Go to the training website. <a href="https://www.accountablehq.com/" target="__blank">https://www.accountablehq.com/</a></li>
                    <li>Create an account using your Gmail account. </li>
                    <li>Click on "Training." </li>
                    <li>Select "HIPAA Training." </li>
                    <li>Begin your training. </li>
                </ul>
                <div className="mt-4">After finishing the training, upload a screenshot of your completion proof to Google Drive or Dropbox. Then, share the link with us, and we will issue your certificate.</div>
                <div className="mt-5 mb-2" style={{fontSize:"20px",fontWeight:"bold"}}>Submit your HIPAA certificate</div>
                <InputComponent value={url} onChange={(value) => setUrl(value)}/>
            </div>
            <SubmitArea onContinue={() => {}} onBack={() => {}}/>
        </div>
    )
}
import { Routes, Route} from 'react-router-dom';
import React from 'react';
import { RequestVAPage, VDA_AdwordsPage, VDR_AdwordsPage, VMA_AdwordsPage, VMB_AdwordsPage, VMH_AdwordsPage, VMR_AdwordsPage, VMS_AdwordsPage } from 'src/Pages';
import { AdwordsHeader } from 'src/Components';

export default function AdwordsRoutes() {

    return (
        <div>
            <AdwordsHeader />
            <div className='adwords-content'>
                <Routes>
                    <Route exact path='/dental' element={<VDA_AdwordsPage />} />
                    <Route exact path='/dental/receptionist' element={<VDR_AdwordsPage />} />
                    <Route exact path='/medical' element={<VMA_AdwordsPage />} />
                    <Route exact path='/medical/receptionist' element={<VMR_AdwordsPage />} />
                    <Route exact path='/medical/scribe' element={<VMS_AdwordsPage />} />
                    <Route exact path='/mental-health' element={<VMH_AdwordsPage />} />
                    <Route exact path='/medical-billing-coding' element={<VMB_AdwordsPage />} />
                    <Route exact path='/request-VA' element={<RequestVAPage />} />
                </Routes>
            </div>
        </div>
    )
}
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import { useEffect } from 'react';
import { hideSpinner, showSpinner } from 'src/Utils/Helper';
import { getCoursesAction } from 'src/Redux/Actions';
import { useNavigate } from 'react-router-dom';

export default function CoursesPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        courses,
        loadingCourses
    } = useSelector(state => state.Portal)

    useEffect(() => {
        showSpinner()
        dispatch(getCoursesAction())
    }, [])

    useEffect(() => {
        if(!loadingCourses) {
            hideSpinner()
            console.log(courses)
        }
    }, [dispatch, loadingCourses])

    return (
        <div className='courses'>
            <div className='container'>
                <h2>Free Training Courses for Virtual Medical Assitants</h2>
                <div className='landing-content'>
                    {/* <div className="practice-item-component shadow-object ">
                        <img src="https://api.gradegetter.com/storage/mimstopics/9TzLXGI4wfg5z9BkOdJlFJToBczi1fGV5FElgkEB.png" alt="practice-logo" className="practice-logo" />
                        <div className="footer">
                            <div className="btn-2 practice-btn text-center active active-btn">Practice 480 Questions</div>
                        </div>
                    </div> */}
                    {
                        courses && courses.map((item, index) => (
                            <div className="course-item" key={index} onClick={() => navigate(`/courses/${item.slug}`)}>
                                <div className="img-wrapper">
                                    <img src={item.image} alt="course name"/>
                                    <div className="type">{item.category.name}</div>
                                    <div className="duration">{item.module_count} Modules | {item.lesson_count} Lessons</div>
                                </div>
                                <div className="title">{item.title}</div>
                                <p dangerouslySetInnerHTML={{ __html: item.summary }}></p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
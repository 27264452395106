
import './style.scss';

export default function FooterBottom({}) {

    return (
        <div className='footer-bottom'>
            <div className='container'>
                <div className='footer-text'>©2025 Honest Taskers. All rights reserved</div>
            </div>
        </div>
    )
}
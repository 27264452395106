import React from "react";
import './style.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";

export default function FeebackComponent() {

    const StarComponent = () => {
        return (
            <div className="star-container">
                <svg viewBox="0 0 16 15" className="widget-svg" style={{width:"20px",height:"20px",transition:"transform 0.2s ease-in-out"}} >
                    <path className="star" d="M7.90577 0.587492L9.82622 5.13287L14.7428 5.55524C15.0838 5.58469 15.2225 6.01017 14.9637 6.23414L11.2344 9.46512L12.3519 14.2717C12.4294 14.6057 12.0675 14.8684 11.7746 14.6909L7.54927 12.1427L3.32397 14.6909C3.03025 14.8676 2.6691 14.6049 2.7466 14.2717L3.86415 9.46512L0.134072 6.23337C-0.124778 6.00939 0.0131721 5.58392 0.354947 5.55447L5.27155 5.13209L7.192 0.587492C7.3253 0.271292 7.77247 0.271292 7.90577 0.587492Z" style={{fill:"rgb(251, 169, 46)",transition:"fill 0.2s ease-in-out"}}></path>
                </svg>
            </div>
        )
    }

    const FeedbackItem = () => {
        return (
            <div className="feedback-component">
                <div className="feedback-header">
                    <img src="https://i.imgur.com/gwtklje.png" alt="avatar" className="avatar" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAaVBMVEUyKDIAAAD5+Pn////////c2tzd291lX2X///////+Ig4j9/f3y8fL////Lycvj4uP////q6Oqxr7H///+xrLGgnKD////////////MyszAvcD///////////////////////+em57///8U66MHAAAAInRSTlMzAObgv5mZQCAQTPLMQH+mz7JmcGZZn1CAgHOvkI9gf29ZYTSjpAAAAgVJREFUSMet1+uanCAMBuAIKCAgKp7m2Nb7v8i6ZVtnZyDIbL//72PkkZhAEYyc3dTSdQtll8XIIhgIyR9MaduMNWyp+3OpFXPiEDZM2Z7D1/QdYQbFnuqGQygnQmcUC0b6V7bzScTxT1pxwFLRewTLGxkhkZrcgli2HYdkuG3FKxa0wtleuthx0OJaPuF2t2ndyi/4ZiEj3e0R3wnPwZy4HQtaQ1ZGKv7hqYLMVOwvngnkhhPziWkD2emZx4bAG9HmD2ahBw/lgOMT+8CChI5zXdeuxjBXcsOug9eU60fQC2qXDbMhilfSoEcGUkEEe14jdYPRGMZeXc+wlAm8qiqMrYPLGcPYqzcTsAHHPjpQ+9gCrTGMvXpNYQUE47Wvx/GqvoPJN7AKlH30wCwPHFg7HsG6D33cMDVpTIIf0vkCzqawitzMcoH5/YthQCqOYd1DLErizUAlmkGx2BhWaBvq3IaDdff+JmEhItp6x7I/0noLo+GNEN/0/ZFl5kQLjw3h2ZjMn7hg+b/Y6eHnPubZmop9rHC5Y8X9caC5djnYXotHLPNGqac5TOYMceK/jY9et5anKe9aGZy3r6RO2V/kKiPD+j1ROq+oQ9aECZsFBsIEuqDMNML5STNzYDUi3fAsB6vQ1WiPcEzp8jz4pWxsrFZskamlbI80y4X5dbCd3BxZB38DEqA4oLQzim8AAAAASUVORK5CYII=" alt="play-icon" className="play-icon" />
                </div>
                <div className="reviewer-info">
                    <div className="v-r v-c">
                        <h2>Dr. Carolyn Rekerdres</h2>
                        <div className="star-area">
                            <div className="star-ratings">
                                {/* <svg className="star-grad" >
                                    <defs>
                                        <linearGradient id="starGrad291787590429676" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" className="stop-color-first" style={{stopColor:"rgb(251, 169, 46)",stopOpacity:"1"}}></stop>
                                            <stop offset="0%" className="stop-color-first" style={{stopColor:"rgb(251, 169, 46)",stopOpacity:"1"}}></stop>
                                            <stop offset="0%" className="stop-color-final" style={{stopColor:"rgb(211, 237, 252)",stopOpacity:"1"}}></stop>
                                            <stop offset="100%" className="stop-color-final" style={{stopColor:"rgb(211, 237, 252)",stopOpacity:"1"}}></stop>
                                        </linearGradient>
                                    </defs>
                                </svg> */}
                                {
                                    Array(5).fill(true).map((item, index) => (
                                        <StarComponent key={`star-${index}`}/>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAMAAACKnBfWAAAAOVBMVEUAAAA4r2hAn3A5q2w6qmw4qWw6q2w6q2w6rGw5qWs5qmw1pWo6rGw4qm06qmo4p2g6rGw6qWw6q2xmPPlNAAAAEnRSTlMAIBC/kEDvz59wUDDfYDAgr4CQHqdzAAAAXElEQVQI1y2NSQ7AMAwCafak6eb/P7ZY2JcRlhjAa2a2gEJkxpu0E+hEYZ6eEx7HZM5kA5bnymzqjahV5wC2E9LaJ2uHtJy7VKNWvddRwL/mmuak3bTGXDr8Agk/4+gHiXQZID4AAAAASUVORK5CYII=" alt="dot" className="dot" />
                    </div>
                    <p class="feedback">I just wanted to say we've been very pleased with frog tutoring for my oldest son who was navigating math challenges with Dyslexia in Middle school and we used frog tutoring and found it to be super helpful and he did great</p>
                </div>
            </div>
        )
    }

    return (
        <div className="feedback-section">
            <div className="container v-r">
                <h1>What they say about us</h1>
                <div className="feedback-items">
                    <Media
                        queries={{
                            lg: "(min-width: 1655px)",
                            md: "(min-width: 1360px) and (max-width: 1654px)",
                            sm: "(min-width: 1032px) and (max-width: 1359px)",
                            normal: "(max-width: 1031px)",
                        }}
                    >
                    {(matches) => (
                        <OwlCarousel 
                            className="owl-theme"
                            stagePadding={
                                matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 0
                            }
                            margin={
                                matches.lg ? 20 : matches.md ? 20 : matches.sm ? 10 : 0
                            }
                            items={matches.lg ? 4 : matches.md ? 3 : matches.sm ? 2 : 1}
                            loop
                            dots={false}
                            autoplay
                            rewind
                            autoplayHoverPause
                            autoplayTimeout={2000}
                            >
                            {
                                Array(4).fill(true).map((item, index) => (
                                    <FeedbackItem key={index}/>
                                ))
                            }
                        </OwlCarousel>
                        )}
                </Media>
                </div>
            </div>
        </div>
    )
}
import React, { useState } from "react";
import './style.scss';
import iconOpen from 'src/Assets/Images/Home/faqUp.svg';
import iconClosed from 'src/Assets/Images/Home/faqDown.svg';


export default function FaqAccordion({item}) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className={`faq-accordion ${isOpen ? 'open' : ''}`}>
            <div className="head" onClick={() => setIsOpen(!isOpen)}>
                <div>{item.question}</div>
                <img src={isOpen ? iconOpen : iconClosed}/>
            </div>
            <div className={`answer ${isOpen ? 'show mt-3' : ''}`}>
                <div dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                {
                    item.options && <ul>
                        {
                            item.options.map((option, index) => (
                                <li key={index}>{option}</li>
                            ))
                        }
                    </ul>
                }
            </div>
        </div>
    )
}
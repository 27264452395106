import React, { useState } from "react";
import './style.scss';
import { InputBoxComponent } from "./InputBox";
import Features from "./Features";
import HeaderComponent from "./HeaderComponent";
import Jobs from "./Jobs";
import FeebackComponent from "./FeebackComponent";

export default function SignupPage() {
    const [firstName, setFirstName] = useState("")

    const iconPerson = `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit" class="injected-svg" data-src="/static/media/user.cf530749c110bc9d1ebbc02a677414bf.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9253 12.6545C17.6226 13.2402 19.1783 14.2077 20.4853 15.5147C22.7518 17.7812 24 20.7947 24 24H22.125C22.125 18.417 17.583 13.875 12 13.875C6.41705 13.875 1.875 18.417 1.875 24H0C0 20.7947 1.24823 17.7812 3.51473 15.5147C4.82175 14.2077 6.37744 13.2402 8.07478 12.6545C6.25683 11.4024 5.0625 9.30694 5.0625 6.9375C5.0625 3.11217 8.17467 0 12 0C15.8253 0 18.9375 3.11217 18.9375 6.9375C18.9375 9.30694 17.7432 11.4024 15.9253 12.6545ZM6.9375 6.9375C6.9375 9.729 9.20855 12 12 12C14.7915 12 17.0625 9.729 17.0625 6.9375C17.0625 4.146 14.7915 1.875 12 1.875C9.20855 1.875 6.9375 4.146 6.9375 6.9375Z" fill="inherit"></path>
        </svg>
    `
    const iconEmail = `
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20" fill="inherit" class="injected-svg" data-src="/static/media/email.c4704d851cb547361778ab745c6c5915.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8027 0H2.19727C0.987207 0 0 0.986133 0 2.19727V16.8457C0 18.0527 0.982813 19.043 2.19727 19.043H22.8027C24.0098 19.043 25 18.0602 25 16.8457V2.19727C25 0.990234 24.0172 0 22.8027 0ZM22.4993 1.46484L12.5466 11.4176L2.50776 1.46484H22.4993ZM1.46484 2.4936V16.5424L8.51948 9.48774L1.46484 2.4936ZM2.50063 17.5781L9.55972 10.519L12.0332 12.9713C12.3195 13.2552 12.7816 13.2542 13.0667 12.969L15.4785 10.5573L22.4994 17.5781H2.50063ZM16.5143 9.52148L23.5352 16.5423V2.50059L16.5143 9.52148Z" fill="inherit"></path>
        </svg>
    `
    const iconPassword = `
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="inherit" class="injected-svg" data-src="/static/media/key.e6fc253293e2ff7312c522c84cb3c06b.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16615 8.89185C8.16615 3.98889 12.155 0 17.058 0C21.9609 0 25.9498 3.98889 25.9497 8.89174C25.9497 13.7947 21.9608 17.7836 17.0579 17.7836C15.9675 17.7836 14.9081 17.5897 13.9021 17.2069L11.8405 19.2685C11.7189 19.39 11.5542 19.4583 11.3823 19.4583H9.988V20.8526C9.988 21.2105 9.69783 21.5006 9.34 21.5006H8.70485V22.1357C8.70485 22.4936 8.41474 22.7837 8.05685 22.7837H6.47234V24.3702C6.47234 24.5423 6.40395 24.7072 6.28217 24.8288L4.99014 26.1189C4.86862 26.2402 4.70398 26.3083 4.53231 26.3083H0.698289C0.340404 26.3083 0.050293 26.0182 0.050293 25.6603V21.0078C0.050293 20.836 0.118582 20.6712 0.240103 20.5497L8.74221 12.0469C8.35981 11.0411 8.16615 9.98184 8.16615 8.89185ZM14.009 15.8508C14.9715 16.2734 15.9973 16.4876 17.058 16.4876C21.2463 16.4876 24.6538 13.0802 24.6539 8.8919C24.6539 4.70348 21.2465 1.29604 17.0581 1.29604C12.8698 1.29604 9.46229 4.70353 9.46229 8.8919C9.46229 9.95219 9.67625 10.978 10.0984 11.9405C10.2077 12.1896 10.1473 12.4699 9.96842 12.6533C9.96662 12.6552 9.96513 12.657 9.96318 12.6589L1.34639 21.2763V22.447L8.44182 15.3516C8.66976 15.1237 9.03889 15.1238 9.26668 15.3516C9.49446 15.5793 9.49446 15.9485 9.26668 16.1763L1.34639 24.0966V25.0123H4.26419L5.1764 24.1015V22.1357C5.1764 21.7778 5.46657 21.4877 5.8244 21.4877H7.40896V20.8526C7.40896 20.4947 7.69912 20.2046 8.05696 20.2046H8.69211V18.8103C8.69211 18.4524 8.98222 18.1623 9.3401 18.1623H11.114L13.2903 15.986C13.2914 15.9848 13.2926 15.9838 13.2937 15.9828C13.2945 15.9821 13.2954 15.9813 13.2962 15.9805C13.4798 15.8017 13.7601 15.7416 14.009 15.8508Z" fill="inherit"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3369 6.18559C17.3369 4.84721 18.4257 3.75839 19.7641 3.75839C21.1025 3.75839 22.1913 4.84721 22.1913 6.18559C22.1913 7.52397 21.1025 8.61279 19.7641 8.61279C18.4257 8.61279 17.3369 7.52397 17.3369 6.18559ZM18.6329 6.18559C18.6329 6.80933 19.1404 7.3168 19.7641 7.3168C20.3879 7.3168 20.8953 6.80933 20.8953 6.18559C20.8953 5.56185 20.3879 5.05438 19.7641 5.05438C19.1404 5.05438 18.6329 5.56185 18.6329 6.18559Z" fill="inherit"></path>
        </svg>
    `

    function SingupHomeComponent() {
        return (
            <div className="va-home">
                <div className="signup-home">
                    <div className="startarea-component">
                        <div className="container v-r">
                            <h1>Get A Job As A Virtual Medical Assistant Supporting U.S Healthcare Organizations</h1>
                            <h2>Join Our Team: The best place to work as medical VA</h2>
                            <div className="row inner-area">
                                <div className="col-lg-6">
                                    <InputBoxComponent 
                                        leadingIcon={iconPerson} 
                                        value={firstName} 
                                        placeholder={"First Name"} 
                                        onChange={(value) => setFirstName(value)}/>
                                </div>
                                <div className="col-lg-6">
                                    <InputBoxComponent 
                                        value={firstName} 
                                        placeholder={"Last Name"} 
                                        onChange={(value) => setFirstName(value)}/>
                                </div>
                                <div className="col-lg-6">
                                    <InputBoxComponent 
                                        leadingIcon={iconEmail}
                                        value={firstName} 
                                        placeholder={"Email"} 
                                        onChange={(value) => setFirstName(value)}/>
                                </div>
                                <div className="col-lg-6">
                                    <InputBoxComponent 
                                        leadingIcon={iconPassword}
                                        value={firstName} 
                                        placeholder={"Create Password"} 
                                        type="password"
                                        onChange={(value) => setFirstName(value)}/>
                                </div>
                            </div>
                            <div className="inner-area content-part">
                                <div className="checkbox-component check-box mb-0" style={{minWidth:"24px",maxWidth:"24px",height:"24px"}}></div>
                                <p>I agree to HonestTaskers' terms of service and privacy policy for tutors<a className="ml-2" href="/privacy">Read More</a> </p>
                            </div>
                            <div className="start-btn center inner-area">Get Started</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } 

    return (
        <div className="signup-page-1">
            <HeaderComponent />
            <SingupHomeComponent />
            <Features />
            <FeebackComponent />
            <Jobs />
        </div>
    )
}
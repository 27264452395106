import React from "react";

export default function InputComponent({label, value, placeholder, onChange, type = "text"}) {

    return (
        <div className="form-group">
            { label && <label>{label}</label>}
            <input type={type}
                className="form-control" 
                placeholder={placeholder} 
                value={value} 
                onChange={e => onChange(e.target.value)}/>
        </div>
    )
}
import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { InputComponent } from "src/Components/Common";
import SubmitArea from "../SubmitArea";

export default function PersonalInfo() {
    const [firstName, setFirstName] = useState("")

    return (
        <div className="va-personal-info">
            <h1>Enter your Personal PersonalInfo</h1>
            <div className="form-content row mt-5">
                <div className="col-lg-6">
                    <InputComponent label={"First Name"} 
                        placeholder={"John"} 
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Last Name"} 
                        placeholder={"Doe"} 
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Title"} 
                        placeholder={"Co-Founder, Chief Medical Officer"} 
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Email"} 
                        placeholder={"john@gmail.com"} 
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Phone Number"} 
                        placeholder={"+1672638837"} 
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Street address"} 
                        placeholder={""} 
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"City"} 
                        placeholder={""} 
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Zipcode/Postal Code"} 
                        placeholder={""} 
                        type="number"
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Qualification"} 
                        placeholder={"Masters In Pharmacy"} 
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"University"} 
                        placeholder={"Texas Christian University"} 
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
            </div>
            <SubmitArea onContinue={() => {}} onBack={() => {}}/>
        </div>
    )
}
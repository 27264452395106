import homeImg from 'src/Assets/Images/VmsHome/HomeImg.svg';
import './style.scss';
import { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { InlineWidget } from 'react-calendly';

export default function RequestVA() {
    const [number, setNumber] = useState('');
    const [period, setPeriod] = useState('');
    const [step, setStep] = useState(1);
    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [practice, setPractice] = useState('')
    const [email, setEmail] = useState('')
    const [code, setCode] = useState("US");
    const [phone, setPhone] = useState('');

    const numberList = ['1', '2', '3', '4+'];
    const periodList = ['this Wk', 'next Wk', 'In 2 Wks', 'In 3 Wks', 'In +1 Month'];
    const typeList = ['Virtual Dental Assistant', 'Virtual Dental Receptionist', 'Virtual Medical Assistant', 'Virtual Medical Receptionist','Virtual Mental Health Assistant',
        'Virtual Billing/Coding Assistant', 'Virtual Scribe', 'Remote Patient Monitoring Assistant', 'Other'
    ]

    const titles = [
        {
            title: "Thanks, that’s what we thought",
            question: "The number of Healthcare VAs needed?"
        },
        {
            title: `Yes! We can support your organization with ${number} healthcare virtual assistant`,
            question: "How soon do you want to get started?"
        },
        {
            title: `Thanks for sharing, we can staff your organization with ${number} Healthcare VA ${period}`,
            question: "Type Healthcare VA Needed?"
        },
        {
            title: `Great! We have awesome ${type} available to support your practice`,
            question: "Please for your practice and organization name so we know who we’re dealing with?"
        },
        {
            title: `Hi ${name}, Thanks for trusting us with your needs. This is the last step`,
            question: "Please for your contact information, we’re HIPAA compliant and takes your privacy seriously "
        },
        {
            title: 'Thank you for requesting a discovery call with Honest Taskers.',
            question: 'Please use the calendar below to schedule a discovery call at a time that works best for you.'
        }
    ]

    const getStepContent = () => {
        switch(step) {
            case 1:
                return (<OptionsComponent onSelect={(item) => setNumber(item)} value={number} list={numberList}/>)
            case 2: 
                return (<OptionsComponent onSelect={(item) => setPeriod(item)} value={period} list={periodList}/>)
            case 3:
                return (<OptionsComponent onSelect={(item) => setType(item)} value={type} list={typeList}/>)
            case 4: 
                return (
                    <div className='row items-content'>
                        <div className='col-lg-6 mb-3'>
                            <InputField value={practice} label={'Practice name'} onChange={(value) => setPractice(value)}/>
                        </div>
                        <div className='col-lg-6 mb-3'>
                            <InputField value={name} label={'Your name'} onChange={(value) => setName(value)}/>
                        </div>
                    </div>
                )
            case 5:
                return (
                    <div className='row items-content'>
                        <div className='col-lg-6 mb-3'>
                            <InputField value={email} label={'Email'} onChange={(value) => setEmail(value)} type='email'/>
                        </div>
                        <div className='col-lg-6 mb-3'>
                            <div className='input-component-2'>
                                <ReactFlagsSelect 
                                    searchable
                                    searchPlaceholder='Search...'
                                    className='custom-flag'
                                    selected={code}
                                    showSelectedLabel={false}
                                    showSecondaryOptionLabel={true}
                                    onSelect={(code) => setCode(code)}
                                />
                                <div className='input-content'>
                                    <input className='form-control' type='tel' onChange={(e) => setPhone(e.target.value)} placeholder='Phone'/>
                                </div>
                            </div>
                            {/* <InputField value={name} label={'Your name'} onChange={(value) => setName(value)}/> */}
                        </div>
                    </div>
                )
            case 6:
                return (
                    <div className='calender-picker'>
                         <InlineWidget url="https://calendly.com/honesttaskers/30min"/>
                    </div>
                )
            default:
                return <div></div>
        }
    }

    return (
        <div className='request-va center'>
            <div className='container d-f j-c'>
                <div className='row w-100'>
                    <div className='col-lg-6 left-section'>
                        <div className='img-wrapper'>
                            <img src={homeImg} alt='request VA img'/>
                        </div>
                    </div>
                    <div className='col-lg-6 right-section'>
                        <div className='title'>{titles[step-1].title}</div>
                        <h2>{titles[step-1].question}</h2>
                        { getStepContent() }
                        <div className='action-row d-f a-c'>
                            <div className='back center' style={{pointerEvents:`${step<=1 ? 'none': ''}`}} onClick={() => setStep(step-1)}>
                                <span className='fa fa-angle-left'></span>Back
                            </div>
                            <div className='continue center' onClick={() => setStep(step+1)}>{step === titles.length ? 'Done': 'Continue'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']

const OptionsComponent = ({onSelect, value, list}) => {
    
    return (
        <div className='items-content row'>
            {
                list.map((item, index) => ( 
                    <div className='col-lg-6 mb-3'>
                        <div className={`item-card ${list[index] === value ? 'selected': ''}`} key={index} onClick={() => onSelect(item)}>
                            <div className='circle center'>{letters[index]}</div>
                            <div>{list[index]}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

 
const InputField = ({value, label, onChange, type = 'text'}) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <div className='input-component-2'>
            <div className='input-content'>
                { (isFocused || value.length > 0) && <div className='focus-label'>{label}</div> }
                <input 
                    className='form-control' 
                    value={value} 
                    type={type}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={label}/>
            </div>
        </div>
    )
}
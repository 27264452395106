
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';

export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS';
export const GET_BLOGS_FAIL = 'GET_BLOGS_FAIL';

export const GET_BLOG = 'GET_BLOG';
export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS';
export const GET_BLOG_FAIL = 'GET_BLOG_FAIL';

export const GET_TOPICS = 'GET_TOPICS';
export const GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS';
export const GET_TOPICS_FAIL = 'GET_TOPICS_FAIL';

export const TOGGLE_CALENDLY_MODAL = 'TOGGLE_CALENDLY_MODAL';

export const SUBMIT_CONTACT = 'SUBMIT_CONTACT';
export const SUBMIT_CONTACT_SUCCESS = 'SUBMIT_CONTACT_SUCCESS';
export const SUBMT_CONTACT_FAIL = 'SUBMT_CONTACT_FAIL';
export const SUBMIT_RECEPTION_CONTACT = 'SUBMIT_RECEPTION_CONTACT';

export const TOGGLE_SKILL_MODAL = 'TOGGLE_SKILL_MODAL';

export const GET_VA_PROFILE = 'GET_VA_PROFILE';
export const GET_VA_PROFILE_SUCCESS = 'GET_VA_PROFILE_SUCCESS';
export const GET_VA_PROFILE_FAIL = 'GET_VA_PROFILE_FAIL';

export const GET_TOPIC_BLOGS = 'GET_TOPIC_BLOGS';
export const GET_TOPIC_BLOGS_SUCCESS = 'GET_TOPIC_BLOGS_SUCCESS';
export const GET_TOPIC_BLOGS_FAIL = 'GET_TOPIC_BLOGS_FAIL';

export const TOGGLE_INTERVIEW_MODAL = 'TOGGLE_INTERVIEW_MODAL';

export const REQUEST_INTERVIEW = 'REQUEST_INTERVIEW';
export const REQUEST_INTERVIEW_SUCCESS = 'REQUEST_INTERVIEW_SUCCESS';
export const REQUEST_INTERVIEW_FAIL = 'REQUEST_INTERVIEW_FAIL';

export const TOGGLE_VIDEO_MODAL = 'TOGGLE_VIDEO_MODAL';

export const TOGGLE_PORTAL_SIDE_MENU = 'TOGGLE_PORTAL_SIDE_MENU';

export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAIL = 'GET_COURSES_FAIL';

export const TOGGLE_EXPERIENCE_MODAL = 'TOGGLE_EXPERIENCE_MODAL';
export const TOGGLE_CERTIFICIATION_MODAL = 'TOGGLE_CERTIFICIATION_MODAL';
export const TOGGLE_SKILL_FORM_MODAL = 'TOGGLE_SKILL_FORM_MODAL';
export const TOGGLE_ATTENTION_TEST_MODAL = 'TOGGLE_ATTENTION_TEST_MODAL';
export const TOGGLE_REVIEW_MODAL = 'TOGGLE_REVIEW_MODAL';

export const SET_OPEN_REVIEW = 'SET_OPEN_REVIEW';

export const SUBMIT_REQUEST_CALL = 'SUBMIT_REQUEST_CALL';
export const SUBMIT_REQUEST_CALL_SUCCESS = 'SUBMIT_REQUEST_CALL_SUCCESS';
export const SUBMIT_REQUEST_CALL_FAIL = 'SUBMIT_REQUEST_CALL_FAIL';
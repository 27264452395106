import React, { useEffect, useRef, useState } from "react";
import './style.scss';
import logo from 'src/Assets/Images/Home/logo.svg';
import menu from 'src/Assets/Images/Home/HeadMenu.svg';
import close from 'src/Assets/Images/Home/Close.svg';
import { Dropdown, Nav, NavItem } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleCalendlyModal, toggleSideMenu } from "src/Redux/Actions";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

export default function Header() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const dropdownRef = useRef(null)

    const [transform, setTransform] = useState(false);

    const {
        sideMenuOpen
    } = useSelector(state => state.Home)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        document.body.addEventListener('click', handleClickOutside);
    }, []);

    const handleScroll = () => {
        setTransform(window.scrollY > 0 ? true : false)
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
    };

    const toggleDropdown = (event) => {
        event.stopPropagation()
        setIsDropdownOpen(!isDropdownOpen)
    }

    useEffect(() => {
        if(isDropdownOpen) {
            setIsDropdownOpen(false)
        }
    }, [location.pathname]);

    const excludePaths = [
        '/signup', 
    ]
    const showClass = () => excludePaths.includes(location.pathname) ? 'd-none' : ''

    return (
        <>
            <div className={`header ${transform ? 'active' : ''} ${showClass()}`}>
                <div className="container">
                    <div className="header-content">
                        <ReactSVG src={logo} className="logo" onClick={() => navigate('/')}/>
                            <nav className="header-nav">
                                <Dropdown show={isDropdownOpen}>
                                    <Dropdown.Toggle className="nav-link" onClick={toggleDropdown}>Services</Dropdown.Toggle>
                                    <Dropdown.Menu ref={dropdownRef}>
                                        <NavLink className="dropdown-item" exact to="/multi-purpose-virtual-medical-assistant">Multi-Purpose Virtual Medical Assistant</NavLink>
                                        <NavLink className="dropdown-item" exact to="/virtual-medical-receptionist">Virtual Medical Receptionist</NavLink>
                                        <NavLink className="dropdown-item" exact to="/virtual-medical-scribe">Virtual Medical Scribe</NavLink>
                                        <NavLink className="dropdown-item" exact to="/virtual-dental-receptionist">Virtual Dental Receptionist</NavLink>
                                        <NavLink className="dropdown-item" exact to="/virtual-medical-billing">Virtual Medical Biller</NavLink>
                                        <NavLink className="dropdown-item" exact to="/virtual-medical-coder">Virtual Medical Coder</NavLink>
                                        <NavLink className="dropdown-item" exact to="/virtual-mental-health-assistant">Virtual Mental Health Assistant</NavLink>
                                        <NavLink className="dropdown-item" exact to="/remote-patient-monitoring-assistant">Remote Patient Monitoring Assistant</NavLink>
                                        <NavLink className="dropdown-item" exact to="/telehealth-medical-assistant">Telehealth Medical Assistant</NavLink>
                                        <NavLink className="dropdown-item" exact to="/telephone-triage-medical-assistant">Telephone Triage Medical Assistant</NavLink>
                                        <NavLink className="dropdown-item" exact to="/virtual-patient-care-coordinator">Virtual Patient Care Coordinator</NavLink>
                                        <NavLink className="dropdown-item" exact to="/remote-mds-coordinator">Remote MDS Coordinator</NavLink>
                                        <NavLink className="dropdown-item" exact to="/remote-clinical-chat-auditor">Remote Clinical Chat Auditor</NavLink>
                                        <NavLink className="dropdown-item" exact to="/virtual-dental-assistant">Virtual Dental Assistant</NavLink>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <NavLink className="nav-link" exact to="/about">About Us</NavLink>
                                <NavLink className="nav-link" exact to="/story">Our Story</NavLink>
                                <NavLink className="nav-link" exact to="/why-us">Why Us</NavLink>
                                <NavLink className="nav-link" exact to="/vision">Vision</NavLink>
                                {/* <NavLink className="nav-link" exact to="/blog">Blog</NavLink> */}
                                <NavLink className="nav-link" exact to="/#pricing">Pricing</NavLink>
                                <div className="nav-link" onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfucq_29cMbGMKsvlYFBcuzrZFoJ4hs5Q2K8Iai1KPJS0b1AA/viewform", '_blank')?.focus()}>Apply</div>
                            </nav>
                            {/* <NavLink className="nav-link sign-in" exact to="/auth/sign-in">Sign In</NavLink> */}
                            <div className="btn-wrapper">
                                <div className="header-btn" onClick={() => dispatch(toggleCalendlyModal(true))}>Book Discovery Call</div>
                            </div>
                    </div>
                </div>
            </div>
            <div className={`mobile-header ${transform ? 'active': ''} ${sideMenuOpen ? 'open' : ''} ${showClass()}`}>
                <div className="container">
                    <div className="content">
                        <ReactSVG src={logo} className="logo" onClick={() => navigate('/')}/>
                        <img className="menu" src={sideMenuOpen ? close : menu } onClick={() => dispatch(toggleSideMenu(!sideMenuOpen))}/>
                    </div>
                </div>
            </div>
        </>
    )
} 
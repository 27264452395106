
import { useState } from 'react';
import './style.scss';

export default function HIPAACompliance({hippas}) {
    const [count, setCount] = useState(4);

    function FeatureItem({item}) {
        return (
            <div className="col-md-6">
                <div className="feature-item">
                    <img src={item.img}/>
                    <div className="info">
                        <div className="title">{item.title}</div>
                        { item.message && <div className="message">{item.message}</div>}
                        { item.options && <ul>
                            {
                                item.options.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))
                            }
                        </ul>}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='hippa'>
            <div className='container'>
                <div className='padding'>
                    <h2>HIPAA Compliance: Ensuring Data Privacy & Security</h2>
                    <p className='text-center text-white pb-5'>Honest Taskers prioritizes the privacy and security of your patients' information by adhering strictly <b>to HIPAA (Health Insurance Portability and Accountability Act)</b> guidelines. Here’s how we ensure compliance:</p>
                    <div className="row mt-5">
                        {
                            hippas.slice(0, count).map((item, index) => (
                                <FeatureItem item={item} key={index}/>
                            ))
                        }
                    </div>
                    <div className='d-f w-100 j-c mt-5'>
                        <div className='btn btn-orange' onClick={() => setCount(count === 4 ? hippas.length : 4)}>{count === 4 ? 'See More' : 'See Less'}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
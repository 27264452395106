import './style.scss';
import { Modal } from 'react-bootstrap';
import { StarComponent } from 'src/Widgets';
 
export const ReviewModal = ({show, onHide, item}) => {

    return (
        <Modal className="review-modal" show={show} onHide={onHide}>
            <div className='review-content'>
                <div className="close-btn" onClick={onHide}><span className='fa fa-close'></span></div>
                <div className='review-item'>
                    <div className="review-header">
                        <div className="avatar center">{item.initials}</div>
                        <div className="reviewer-info flex-1">
                            <h3 className='name'>{item.name}</h3>
                            {/* <div className="address v-c">Fort Worth, TX • Jan 23, 2024</div> */}
                        </div>
                    </div>
                    <div className='user-rating d-f a-c'>
                        {
                            Array(5).fill(true).map((item, index) => (
                                <StarComponent key={index}/>
                            ))
                        }
                    </div>
                    <div className='message'>{item.message}</div>
                    {/* <p className="continue-btn mb-0">Continue Reading</p> */}
                </div>
            </div>
        </Modal>
    )
}
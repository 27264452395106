
import './style.scss';
import { AdwordsVideoModal } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideoModal } from 'src/Redux/Actions';

export default function Showcase({
    titleVA = "Who Are Our Virtual Dental Assistants?",
    titleClient = "Client Review"
}) {
    const dispatch = useDispatch()
    const {
        showVideoModal
    } = useSelector(state => state.Home)

    return (
        <div className="showcase">
            <AdwordsVideoModal show={showVideoModal} onHide={() => dispatch(toggleVideoModal(false))}/>
            <div className="container">
                <div className="padding">
                    <h2 className='pb-5'>{titleVA}</h2>
                    <div className='row main-row mt-5 no-gutter'>
                        <div className='col-lg-6 left-container'>
                            <div className='left-area'>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAMAAACKnBfWAAAAOVBMVEUAAAA4r2hAn3A5q2w6qmw4qWw6q2w6q2w6rGw5qWs5qmw1pWo6rGw4qm06qmo4p2g6rGw6qWw6q2xmPPlNAAAAEnRSTlMAIBC/kEDvz59wUDDfYDAgr4CQHqdzAAAAXElEQVQI1y2NSQ7AMAwCafak6eb/P7ZY2JcRlhjAa2a2gEJkxpu0E+hEYZ6eEx7HZM5kA5bnymzqjahV5wC2E9LaJ2uHtJy7VKNWvddRwL/mmuak3bTGXDr8Agk/4+gHiXQZID4AAAAASUVORK5CYII=" alt="dot" class="dot" />
                                <div><strong>Meet Marcela</strong></div>
                                <div><strong>Country:</strong> Nicaragua</div>
                                <div><strong>Degree:</strong> Marketing</div>
                                <div><strong>Supporting:</strong> Value Dental, New Jersey</div>
                            </div>
                        </div>
                        <div className='col-lg-6 right-container d-f'>
                            <div className='right-area' onClick={() => dispatch(toggleVideoModal(true))}>
                                <img alt="video" class="video" src="https://i.imgur.com/EyeDWJM.png" />
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAaVBMVEUyKDIAAAD5+Pn////////c2tzd291lX2X///////+Ig4j9/f3y8fL////Lycvj4uP////q6Oqxr7H///+xrLGgnKD////////////MyszAvcD///////////////////////+em57///8U66MHAAAAInRSTlMzAObgv5mZQCAQTPLMQH+mz7JmcGZZn1CAgHOvkI9gf29ZYTSjpAAAAgVJREFUSMet1+uanCAMBuAIKCAgKp7m2Nb7v8i6ZVtnZyDIbL//72PkkZhAEYyc3dTSdQtll8XIIhgIyR9MaduMNWyp+3OpFXPiEDZM2Z7D1/QdYQbFnuqGQygnQmcUC0b6V7bzScTxT1pxwFLRewTLGxkhkZrcgli2HYdkuG3FKxa0wtleuthx0OJaPuF2t2ndyi/4ZiEj3e0R3wnPwZy4HQtaQ1ZGKv7hqYLMVOwvngnkhhPziWkD2emZx4bAG9HmD2ahBw/lgOMT+8CChI5zXdeuxjBXcsOug9eU60fQC2qXDbMhilfSoEcGUkEEe14jdYPRGMZeXc+wlAm8qiqMrYPLGcPYqzcTsAHHPjpQ+9gCrTGMvXpNYQUE47Wvx/GqvoPJN7AKlH30wCwPHFg7HsG6D33cMDVpTIIf0vkCzqawitzMcoH5/YthQCqOYd1DLErizUAlmkGx2BhWaBvq3IaDdff+JmEhItp6x7I/0noLo+GNEN/0/ZFl5kQLjw3h2ZjMn7hg+b/Y6eHnPubZmop9rHC5Y8X9caC5djnYXotHLPNGqac5TOYMceK/jY9et5anKe9aGZy3r6RO2V/kKiPD+j1ROq+oQ9aECZsFBsIEuqDMNML5STNzYDUi3fAsB6vQ1WiPcEzp8jz4pWxsrFZskamlbI80y4X5dbCd3BxZB38DEqA4oLQzim8AAAAASUVORK5CYII=" alt="play-icon" class="play-icon"></img>
                            </div>
                        </div>
                    </div>
                    <h2 className='mt-5'>{titleClient}</h2>
                    <div className='row main-row mt-5 no-gutter'>
                        <div className='col-lg-6 left-container'>
                            <div className='left-area'>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAMAAACKnBfWAAAAOVBMVEUAAAA4r2hAn3A5q2w6qmw4qWw6q2w6q2w6rGw5qWs5qmw1pWo6rGw4qm06qmo4p2g6rGw6qWw6q2xmPPlNAAAAEnRSTlMAIBC/kEDvz59wUDDfYDAgr4CQHqdzAAAAXElEQVQI1y2NSQ7AMAwCafak6eb/P7ZY2JcRlhjAa2a2gEJkxpu0E+hEYZ6eEx7HZM5kA5bnymzqjahV5wC2E9LaJ2uHtJy7VKNWvddRwL/mmuak3bTGXDr8Agk/4+gHiXQZID4AAAAASUVORK5CYII=" alt="dot" class="dot" />
                                <div><strong>Dr. George Lukakis</strong> </div>
                                <div><strong>Value Dental</strong></div>
                                <div><strong>New Jersey, NJ</strong></div>
                            </div>
                        </div>
                        <div className='col-lg-6 right-container d-f'>
                            <div className='right-area' onClick={() => dispatch(toggleVideoModal(true))}>
                                <img alt="video" class="video" src="https://i.imgur.com/EyeDWJM.png" />
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAaVBMVEUyKDIAAAD5+Pn////////c2tzd291lX2X///////+Ig4j9/f3y8fL////Lycvj4uP////q6Oqxr7H///+xrLGgnKD////////////MyszAvcD///////////////////////+em57///8U66MHAAAAInRSTlMzAObgv5mZQCAQTPLMQH+mz7JmcGZZn1CAgHOvkI9gf29ZYTSjpAAAAgVJREFUSMet1+uanCAMBuAIKCAgKp7m2Nb7v8i6ZVtnZyDIbL//72PkkZhAEYyc3dTSdQtll8XIIhgIyR9MaduMNWyp+3OpFXPiEDZM2Z7D1/QdYQbFnuqGQygnQmcUC0b6V7bzScTxT1pxwFLRewTLGxkhkZrcgli2HYdkuG3FKxa0wtleuthx0OJaPuF2t2ndyi/4ZiEj3e0R3wnPwZy4HQtaQ1ZGKv7hqYLMVOwvngnkhhPziWkD2emZx4bAG9HmD2ahBw/lgOMT+8CChI5zXdeuxjBXcsOug9eU60fQC2qXDbMhilfSoEcGUkEEe14jdYPRGMZeXc+wlAm8qiqMrYPLGcPYqzcTsAHHPjpQ+9gCrTGMvXpNYQUE47Wvx/GqvoPJN7AKlH30wCwPHFg7HsG6D33cMDVpTIIf0vkCzqawitzMcoH5/YthQCqOYd1DLErizUAlmkGx2BhWaBvq3IaDdff+JmEhItp6x7I/0noLo+GNEN/0/ZFl5kQLjw3h2ZjMn7hg+b/Y6eHnPubZmop9rHC5Y8X9caC5djnYXotHLPNGqac5TOYMceK/jY9et5anKe9aGZy3r6RO2V/kKiPD+j1ROq+oQ9aECZsFBsIEuqDMNML5STNzYDUi3fAsB6vQ1WiPcEzp8jz4pWxsrFZskamlbI80y4X5dbCd3BxZB38DEqA4oLQzim8AAAAASUVORK5CYII=" alt="play-icon" class="play-icon"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}